import { useEffect, useRef, useState } from 'react';
import { Box, DatePicker } from '../ui';
import { GrayCalenderIcon, GreenCheckIcon } from './icons';
import { LayoutBox } from './Layout';

export const CustomDatePicker = ({ label, placeholder, shouldShowPicker = false, setShouldShowPicker, date, setDate }) => {
  const inputRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShouldShowPicker(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [setShouldShowPicker]);

  return (
    <LayoutBox row gap={12}>
      <Box className="input-container" onClick={() => setShouldShowPicker(!shouldShowPicker)} ref={inputRef} flexDirection="row">
        <span className="calendar-icon">
          <GrayCalenderIcon />
        </span>
        <input type="text" className="custom-input" placeholder={placeholder} value={date ? date.format('MM-DD-YYYY') : ''} readOnly />
        <span className="input-label">{label}</span>
      </Box>
      {shouldShowPicker && (
        <DatePicker
          style={{
            position: 'absolute',
            top: '50px',
            left: '0px',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
          }}
          backgroundColor="white"
          zIndex={5}
          date={date}
          onChange={(date) => {
            setDate(date);
            setShouldShowPicker(false);
          }}
          borderRadius={16}
          paddingX={'2px'}
          columnPadding={0}
          rowPadding={0}
          rowWidth={250}
          headerHeight={'35px'}
          contentProps={{ height: '250px', overflowY: 'auto' }}
        />
      )}
    </LayoutBox>
  );
};
