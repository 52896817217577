import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import 'ag-grid-community/styles/ag-grid.css'; // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Theme
import 'ag-grid-enterprise';
import { ActivityIndicator, Box, Menu, MenuItem, Text } from '../ui';
import { LayoutBox } from './Layout';
import { ChevronDownIcon } from './icons';

const CustomHeader = ({ column }, updateColumnDef) => {
  const [showSearchDropdown, setShowSearchDropdown] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState(column.colDef.headerName);
  const options = column.colDef.options;

  useEffect(() => setFilteredOptions(column.colDef?.label), [column]);

  const refInput = useRef();

  const handleOptionClick = (label) => {
    setShowSearchDropdown(false);
    setSelectedColumn(label);
    const selectedOptionIndex = column.colDef.label.findIndex((opt) => opt === label);
    const formatters = column.colDef.availableFormatters;
    const fieldName = options[selectedOptionIndex];
    updateColumnDef({
      ...column.colDef,
      field: options[selectedOptionIndex], // Set the corresponding option from 'options' array
      headerName: label,
      ...(formatters && { valueFormatter: formatters[fieldName] }), // Conditional addition of valueFormatter key
    });
  };

  return (
    <>
      <LayoutBox className="custom-header" style={{ display: 'flex', width: '100%' }} row>
        <LayoutBox row alignItems="center" paddingLeft="0px" alignSelf="stretch" width="90%" style={{ display: 'flex' }} gap={4} ref={refInput}>
          <Text
            color="#637381"
            maxLines={2}
            value={selectedColumn}
            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'block', fontWeight: '700px', fontSize: '13px' }}
          />
          {!!column?.colDef?.options?.length && (
            <Box onClick={() => setShowSearchDropdown(!showSearchDropdown)} width="16px" height="16px" style={{ cursor: 'pointer' }}>
              <ChevronDownIcon />
            </Box>
          )}
        </LayoutBox>
      </LayoutBox>
      <div className="search-dropdown" style={{ minWidth: 40 }}>
        <Box justifyContent="flex-end" onPress={() => setShowSearchDropdown(true)}>
          <Menu
            anchorNode={refInput.current}
            open={showSearchDropdown}
            onClose={() => setShowSearchDropdown(false)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            {!!filteredOptions?.length &&
              filteredOptions?.map((option, index) => (
                <MenuItem
                  key={option}
                  borderTopWidth={index === 0 ? 0 : 1}
                  borderTopColor="$gray.100"
                  selected={option === selectedColumn}
                  onClick={() => handleOptionClick(filteredOptions[index] && filteredOptions[index])}
                >
                  {filteredOptions[index] && filteredOptions[index]}
                </MenuItem>
              ))}
          </Menu>
        </Box>
      </div>
    </>
  );
};

const Spinner = () => {
  return (
    <LayoutBox style={{ position: 'relative', minHeight: '100vh' }} width="100%">
      <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 999, margin: 'auto' }}>
        <ActivityIndicator />
      </Box>
    </LayoutBox>
  );
};

export const TableRenderer = (props) => {
  const { styles, ref, renderHeader, data, onSelect, defaultColDefs, loading } = props;
  const [colDefs, setColDefs] = useState(defaultColDefs);
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    setRowData(data);
  }, [colDefs, data]);

  const headerStyle = {
    width: '100%',
    marginTop: '30px',
  };
  const updateColumnDef = (newColDef) => {
    setColDefs((prevColDefs) => prevColDefs.map((colDef) => (colDef.id === newColDef.id ? newColDef : colDef)));
  };
  const sticky = {
    position: 'sticky',
    top: 0,
  };
  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 150,
      headerComponent: (props) => CustomHeader(props, updateColumnDef),
      cellStyle: { color: '#637381', fontSize: '14px', fontWeight: '400px' },
    }),
    []
  );

  const gridOptions = {
    suppressHorizontalScroll: true, // Ensure horizontal scrolling is disabled
    floatingFiltersHeight: 0,
    headerHeight: 48,
    onRowClicked: (params) => {
      onSelect(params.data);
    },
  };

  return (
    <LayoutBox
      style={{
        width: '100%',
        alignSelf: 'stretch',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: '85px',
      }}
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          {renderHeader({
            style: [sticky, headerStyle],
            sticky: true,
            height: '256px',
            styles,
            shouldShowColumnRow: false,
            justifyContent: 'flex-start',
          })}
          <div
            className="ag-theme-quartz no-scrollbar"
            style={{ ...styles, width: '100%', minHeight: '200px', overflow: 'auto', maxHeight: '800px' }}
          >
            <AgGridReact
              rowData={rowData}
              columnDefs={colDefs}
              ref={ref}
              headerHeight="48"
              reactiveCustomComponents
              defaultColDef={defaultColDef}
              gridOptions={gridOptions}
            />
          </div>
        </>
      )}
    </LayoutBox>
  );
};
