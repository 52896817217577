import { useEffect, useMemo, useState } from 'react';
import { Box, Button, Grid, GridModal, Text } from '../../ui';
import { Colors, TextColors } from '../../constants';
import { withStyles } from '../../ui/styling';
import { usePolicyPageContext } from './context/PolicyPageContext';
import { useParams } from 'react-router-dom';

import { useApi, useNavigate } from '../../hooks';
import { capitalize, isEmpty, normalizeCamelCase } from '../../ui/utils';
import { currencyFormatter } from '../QuotePage/content/QuoteGrids/utils';
import { CustomDatePicker, LayoutBox } from '../../components';
import moment from 'moment-timezone';

export const Badge = withStyles(({ color, bgColor, uppercase }) => ({
  root: {
    borderRadius: 6,
    backgroundColor: bgColor ? bgColor : '$gray.400',
    display: 'inline-flex',
    margin: '2px 8px',
    alignItems: 'center',
    paddingY: '2px',
    paddingX: '8px',
    gap: '6px',
  },
  text: {
    props: {
      size: 'xSmall',
      weight: 700,
      onColor: bgColor ? bgColor : '$gray.400',
      align: 'center',
      color: color || '$gray.600',
      uppercase: uppercase || false,
    },
  },
}))((props) => {
  const { styles, color, value, ...rest } = props;
  return (
    <Box {...rest}>
      <Text style={styles.text} {...styles.props.text} value={value}></Text>
    </Box>
  );
});

const ManageMCEModal = ({ showModal, setShowModal, policyId }) => {
  const [shouldEditEffectiveDate, setShouldEditEffectiveDate] = useState(false);
  const [effectiveDate, setEffectiveDate] = useState(moment());
  const [inProgress, setInProgress] = useState(false);
  const { updatePolicyMCEs } = useApi();
  const navigate = useNavigate();

  const handleOnSubmit = async () => {
    setInProgress(true);
    try {
      const response = await updatePolicyMCEs(policyId, {
        type: 'locationChange',
        effectiveAt: effectiveDate.format('YYYY-MM-DD'),
      });
      const newMCEId = response[0]?.id;
      if (newMCEId) {
        navigate.to(`/policies/${policyId}/mces/${newMCEId}`);
      } else {
        console.error('Failed to retrieve the MCE ID after creation');
        alert('An error occurred: Unable to retrieve the MCE ID.');
      }
    } catch (error) {
      console.error('Failed to update monthly reporting data:', error);
      alert('Unable to Update the MCE, Please try again later', error);
    } finally {
      setShowModal(false);
      setInProgress(false);
    }
  };

  return (
    <GridModal
      title={'create Location Change MCE'}
      buttonText={'OK'}
      handlePress={handleOnSubmit}
      open={showModal}
      onClose={() => setShowModal(false)}
      width="480px"
      capitalizeTitle={true}
      loading={inProgress}
      disabled={inProgress}
    >
      <LayoutBox gap={16} padding="16px">
        <LayoutBox row alignItems="flex-start" padding="8px" paddingLeft="0px" gap={8} alignSelf="stretch" width="100%">
          <Box style={{ color: '#637381', fontSize: 16, fontWeight: 500, alignItems: 'flex-start' }} width="40%" marginY="auto">
            <Text style={{ color: '#212B36', fontSize: 16, fontWeight: 500 }}>Effective At:</Text>
          </Box>
          <Box style={{ color: '#637381', fontSize: 16, fontWeight: 500 }} width="50%">
            <CustomDatePicker
              label="Date"
              placeholder={'MM/DD/YYYY'}
              shouldShowPicker={shouldEditEffectiveDate}
              setShouldShowPicker={setShouldEditEffectiveDate}
              setDate={setEffectiveDate}
              date={effectiveDate}
            />
          </Box>
        </LayoutBox>
      </LayoutBox>
    </GridModal>
  );
};

const PolicyGridData = (props) => {
  const navigate = useNavigate();
  const { setGridReference } = props;
  const { policyId } = useParams();
  const [selectedMceId, setSelectedMceId] = useState(null);
  const [MCEsList, setMCEsList] = useState([]);
  const { MCEsData } = usePolicyPageContext();
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
  const [policyMCEs, setPolicyMCEs] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const { downloadMCE, deleteMCE, getPolicyMCEs } = useApi();

  const getColDef = useMemo(
    (policyMCEs) => {
      const colDefs = [
        {
          headerName: '',
          maxWidth: 50,
          suppressRowClickSelection: true,
          cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
          cellRenderer: (params) => (
            <div
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <input
                type="radio"
                name="mceId"
                checked={selectedMceId === params.data.id}
                onChange={() => setSelectedMceId(params.data.id)}
                style={{
                  transform: 'scale(1.5)',
                  marginRight: 8,
                }}
              />
            </div>
          ),
        },

        {
          field: 'mceNumber',
          headerName: 'MCE Number',
          suppressRowClickSelection: true,
          minWidth: 300,
        },
        {
          field: 'status',
          headerName: 'Status',
          cellRenderer: ({ data: { status } }) => (
            <Badge value={capitalize(status)} bgColor={Colors.quoteStatus[capitalize(status)]} color={TextColors[capitalize(status)]} />
          ),
        },
        {
          field: 'type',
          headerName: 'Type',
          cellRenderer: ({ data: { type } }) => <Badge value={normalizeCamelCase(type)} bgColor={Colors.mceStatus[type]} color={TextColors[type]} />,
        },
        {
          field: 'notes',
          headerName: 'Notes',
          valueFormatter: (params) => {
            const value = params.data.notes || '';
            return value.length > 35 ? `${value.slice(0, 35)}...` : value;
          },
        },
        {
          field: 'effectiveAt',
          headerName: 'Effective Date',
          valueFormatter: ({ data: { effectiveAt } }) => (effectiveAt ? effectiveAt : 'N/A'),
        },
        {
          field: 'effectiveUntil',
          headerName: 'Effective Until',
          valueFormatter: ({ data: { effectiveUntil } }) => (effectiveUntil ? effectiveUntil : 'N/A'),
        },
        {
          field: 'netPremium',
          headerName: 'Premium',
          valueFormatter: (params) => {
            return !isEmpty(params.data) ? currencyFormatter({ field: params, withDecimals: true }) : '';
          },
          cellStyle: (params) => {
            return params.value < 0 ? { color: 'red' } : null;
          },
        },
      ];

      if (policyMCEs && policyMCEs.length) {
        const feeNames = new Set();
        policyMCEs.forEach((mce) => {
          mce.fees.forEach((fee) => {
            feeNames.add(fee.name);
          });
        });

        feeNames.forEach((feeName) => {
          colDefs.push({
            field: `fees.${feeName}.amount`,
            headerName: feeName,
            valueFormatter: ({ data }) =>
              data.fees && data.fees[feeName] ? currencyFormatter({ field: { value: data.fees[feeName].amount }, withDecimals: true }) : 'N/A',
          });
        });
      }
      return colDefs;
    },
    [selectedMceId]
  );

  useEffect(() => {
    setPolicyMCEs(MCEsData);
  }, [MCEsData]);

  useEffect(() => {
    if (policyMCEs && policyMCEs.length) {
      const mappedMCEs = policyMCEs
        .sort((a, b) => new Date(b.effectiveAt) - new Date(a.effectiveAt))
        .map((mce) => {
          const fees = {};
          mce.fees.forEach((fee) => {
            fees[fee.name] = { amount: fee.amount };
          });
          return { ...mce, fees };
        });
      setMCEsList(mappedMCEs);
    }
  }, [policyMCEs]);

  const handleDownloadMCE = async () => {
    setIsDownloadInProgress(true);
    try {
      if (selectedMceId) {
        const response = await downloadMCE(selectedMceId, policyId);
        if (response && response.request && response.request.responseURL) {
          const downloadUrl = response.request.responseURL;
          window.open(downloadUrl, '_blank');
        } else {
          alert('Failed to download the MCE. Please try again.');
        }
      } else {
        alert('Please select an MCE to download.');
      }
    } catch (error) {
      alert('An error occurred while trying to download the MCE. Please try again.');
      console.error(error);
    } finally {
      setIsDownloadInProgress(false);
      setSelectedMceId(null);
    }
  };

  const handleDeleteMCE = async () => {
    setIsDeleteInProgress(true);
    try {
      if (selectedMceId) {
        await deleteMCE(policyId, selectedMceId);
        const MCEs = await getPolicyMCEs(policyId);
        setPolicyMCEs(MCEs);
      } else {
        alert('Please select an MCE to download.');
      }
    } catch (error) {
      alert('An error occurred while trying to download the MCE. Please try again.');
    } finally {
      setIsDeleteInProgress(false);
      setSelectedMceId(null);
    }
  };

  const handleCellClicked = (params) => {
    if (params.colDef.headerName === '') return;
    const mceId = params.data?.id;
    if (mceId) {
      mceId &&
        setTimeout(() => {
          navigate.to(`/policies/${policyId}/mces/${mceId}`);
        }, 100);
    }
  };

  return (
    <Box>
      <div className="ag-theme-quartz" style={{ maxHeight: '600px', overflowY: 'auto' }}>
        <Grid
          data={MCEsList}
          columns={getColDef}
          setGridReference={setGridReference}
          rowSelection="multiple"
          shouldShowPagination={false}
          columnWidth={150}
          floatingFilter={false}
          onCellClicked={handleCellClicked}
        />
      </div>
      {showModal && <ManageMCEModal showModal={showModal} setShowModal={setShowModal} policyId={policyId} />}
      <Box display="flex" justifyContent="center" flexWrap="wrap" backgroundColor="white" height={70}>
        <LayoutBox row alignItems="center" padding="8px" paddingLeft="0px" gap={8} alignSelf="stretch" width={'85%'}>
          {!!MCEsList.length && (
            <Box border={'1px solid #919EAB52'} borderRadius={8} marginLeft="$2">
              <Button
                color="#5F2994"
                variant="text"
                label={isDownloadInProgress ? '' : 'Download MCE'}
                paddingX={16}
                minHeight={40}
                minWidth={120}
                onPress={handleDownloadMCE}
                loading={isDownloadInProgress}
              />
            </Box>
          )}
          <Box border="1px solid #919EAB52" borderRadius={8} margin="$2">
            <Button
              color="#5F2994"
              variant="text"
              label="Create Location Change MCE"
              paddingX={16}
              minHeight={40}
              minWidth={120}
              onPress={() => {
                setShowModal(true);
              }}
            />
          </Box>
        </LayoutBox>
        {!!MCEsList.length && (
          <Box border={'1px solid #919EAB52'} borderRadius={8} margin="$2" width={'10%'}>
            <Button
              color="red"
              variant="text"
              label={isDeleteInProgress ? '' : 'Delete MCE'}
              paddingX={8}
              minHeight={40}
              onPress={handleDeleteMCE}
              loading={isDeleteInProgress}
              disabled={isDeleteInProgress}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
export const MiscPolicyDetails = (props) => {
  return <PolicyGridData {...props} />;
};
