import React, { useState, useEffect, useCallback, useRef, useMemo, useImperativeHandle } from 'react';
import moment from 'moment-timezone';
import { useTheme } from '../../hooks';
import { Box } from '../Box';
import { DaySelect } from './DaySelect';
import { MonthSelect } from './MonthSelect';
import { YearSelect } from './YearSelect';
import { CalendarHeader } from './CalendarHeader';
import {
  CalendarViewTypes,
  CALENDAR_HEADER_SPACING,
  CALENDAR_HEADER_HEIGHT,
  DEFAULT_ROW_WIDTH,
  DEFAULT_ROW_PADDING,
  DEFAULT_COLUMN_PADDING,
} from './constants';

const Calendar = React.forwardRef(function Calendar(props, ref) {
  const {
    onChange, // onValueChange
    date, // momentDate
    month, // initial date Calendar will start with if not using date or initialMonth
    initialDate, // momentDate similar to month that only sets the intial month viewed
    // disableKeyboardControl = false, // TODO: implement keyboard controls
    minDate: minDateProp, // moment date (min selectable date)
    maxDate: maxDateProp, // moment date (max selectable date)
    view = CalendarViewTypes.day, // 'date', 'year', or 'month'
    views = Object.keys(CalendarViewTypes), // array of views available to show
    renderDay, // customer render function for day
    renderMonth,
    renderYear,
    renderLoading,
    isDateDisabled, // function that returns true or false for moment date
    onMonthChange, // function that can return a promise if should show loading until return
    onYearChange, // function that can return a promise if should show loading until return
    changeView,
    disableHeader = false,
    hideHeader = false,
    HeaderComponent = CalendarHeader,
    HeaderProps,
    rowWidth = DEFAULT_ROW_WIDTH,
    rowPadding = DEFAULT_ROW_PADDING,
    columnPadding = DEFAULT_COLUMN_PADDING,
    disableHighlightToday = false,
    insets = {},
    disableHighlightTodaysMonth,
    headerHeight: toolbarHeight,
    contentProps,
    ...rest
  } = props;

  const initialMonth = useRef(initialDate ? initialDate.format('YYYYMM') : null);

  const minDate = useMemo(() => {
    if (!minDateProp) {
      return moment('19000101', 'YYYYMMDD');
    }
    return minDateProp;
  }, [minDateProp]);

  const maxDate = useMemo(() => {
    if (!maxDateProp) {
      const possible = moment(`${moment().add(5, 'years').year()}1231`, 'YYYYMMDD');
      if (minDate.format('YYYYMMDD') >= possible.format('YYYYMMDD')) {
        return minDate.clone().add(5, 'years');
      }
      return possible;
    }
    return maxDateProp;
  }, [maxDateProp, minDate]);

  const mounted = useRef(true);

  const handleChangeView = useCallback(
    (next) => {
      if (changeView && CalendarViewTypes[next] && views.includes(next)) {
        changeView(next);
      }
    },
    [views, changeView]
  );

  const [currentMonth, setCurrentMonth] = useState(() => {
    if (date) {
      return date.format('YYYYMM');
    }
    const maxMonth = maxDate ? maxDate.format('YYYYMM') : 999999;
    const minMonth = minDate ? minDate.format('YYYYMM') : -1;
    const initMonth = initialMonth.current;

    if (initMonth && initMonth <= maxMonth && initMonth >= minMonth) {
      return initMonth;
    }

    if (month && month <= maxMonth && month >= minMonth) {
      return month;
    }

    const currMonth = moment().format('YYYYMM');
    if (currMonth <= maxMonth && currMonth >= minMonth) {
      return currMonth;
    }
    if (maxDate) {
      return maxMonth;
    }
    if (minDate) {
      return minMonth;
    }
    return initMonth || month || currMonth;
  });

  const lastMonth = useRef(currentMonth);

  const currentMonthIsValidOnMount = useRef(true);
  // only used if the date prop on mount existed and was invalid and then changed
  useEffect(() => {
    if (!currentMonthIsValidOnMount.current && date) {
      currentMonthIsValidOnMount.current = true;
      setCurrentMonth(date.format('YYYYMM'));
    }
  }, [date]);

  useEffect(() => {
    if (date) {
      let okDate = date;
      if (isDateDisabled && isDateDisabled(date)) {
        okDate = moment();
      }
      if (minDate) {
        if (okDate.format('YYYYMMDD') < minDate.format('YYYYMMDD')) {
          okDate = minDate.clone();
        }
      }
      if (maxDate) {
        if (okDate.format('YYYYMMDD') > maxDate.format('YYYYMMDD')) {
          okDate = maxDate.clone();
        }
      }
      if (okDate !== date && onChange) {
        currentMonthIsValidOnMount.current = false;
        onChange(okDate);
      }
    }
    return () => {
      mounted.current = false;
    };
  }, [date, isDateDisabled, maxDate, minDate, onChange]);

  const [loading, setLoading] = useState(false);

  const handleMonthUpdate = useCallback(
    (nextMonth, nextView) => {
      if (!loading) {
        setCurrentMonth(nextMonth);
        if (nextView && changeView) {
          changeView(nextView);
        }
      }
    },
    [changeView, loading]
  );

  useEffect(() => {
    if (!loading && currentMonth !== lastMonth.current && mounted.current) {
      const handleUpdate = async () => {
        if (onYearChange || onMonthChange) {
          setLoading(true);
          if (onYearChange) {
            const currYear = currentMonth.substring(0, 4);
            const nextYear = currentMonth.substring(0, 4);
            if (currYear !== nextYear) {
              await onYearChange(nextYear);
            }
          }
          if (onMonthChange) {
            await onMonthChange(currentMonth);
          }
        }
        if (mounted.current) {
          setLoading(false);
          lastMonth.current = currentMonth;
        }
      };
      handleUpdate();
    }
  }, [currentMonth, onMonthChange, onYearChange, loading]);

  const theme = useTheme();
  const { top = 0, left = 0, right = 0, bottom = 0 } = insets;

  const headerHidden = useMemo(() => {
    if (typeof hideHeader === 'function') {
      return hideHeader(view);
    }
    return hideHeader === true;
  }, [hideHeader, view]);

  const headerHeight = disableHeader ? 0 : CALENDAR_HEADER_HEIGHT + theme.spacing(CALENDAR_HEADER_SPACING);

  const viewHeight = useMemo(() => {
    return (rowWidth / 7 + rowPadding * 2) * 7 + top + bottom;
  }, [rowWidth, rowPadding, top, bottom]);

  let calendar = null;
  switch (view) {
    case CalendarViewTypes.day:
      calendar = (
        <DaySelect
          date={date}
          padTop={top}
          padBottom={bottom}
          padLeft={left}
          padRight={right}
          month={currentMonth}
          viewHeight={viewHeight}
          rowWidth={rowWidth}
          rowPadding={rowPadding}
          columnPadding={columnPadding}
          minDate={minDate}
          maxDate={maxDate}
          renderDay={renderDay}
          onChange={onChange}
          isDateDisabled={isDateDisabled}
          loading={loading}
          renderLoading={renderLoading}
          disableHighlightToday={disableHighlightToday}
        />
      );
      break;
    case CalendarViewTypes.month:
      calendar = (
        <MonthSelect
          date={date}
          month={currentMonth}
          padTop={top}
          padBottom={bottom}
          padLeft={left}
          padRight={right}
          viewHeight={viewHeight}
          rowWidth={rowWidth}
          rowPadding={rowPadding}
          columnPadding={columnPadding}
          onChange={handleMonthUpdate}
          minDate={minDate}
          maxDate={maxDate}
          renderMonth={renderMonth}
          isDateDisabled={isDateDisabled}
          disableHighlightTodaysMonth={typeof disableHighlightTodaysMonth === 'boolean' ? disableHighlightTodaysMonth : disableHighlightToday}
        />
      );
      break;
    case CalendarViewTypes.year:
      calendar = (
        <YearSelect
          date={date}
          year={currentMonth}
          viewHeight={viewHeight + headerHeight}
          rowWidth={rowWidth}
          padLeft={left}
          padRight={right}
          rowPadding={rowPadding}
          columnPadding={columnPadding}
          onChange={handleMonthUpdate}
          minDate={minDate}
          maxDate={maxDate}
          renderYear={renderYear}
          isDateDisabled={isDateDisabled}
        />
      );
      break;
    default:
      calendar = null;
  }

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
      setCurrentMonth: (nextMonth) => {
        if (nextMonth && !loading && nextMonth !== lastMonth.current) {
          lastMonth.current = nextMonth;
          setCurrentMonth(nextMonth);
        }
      },
    }),
    [loading]
  );

  return (
    <Box justifyContent="center" alignItems="center" width="100%" ref={nodeRef} {...rest}>
      {!disableHeader && view !== CalendarViewTypes.year && !headerHidden ? (
        <HeaderComponent
          date={date}
          view={view}
          changeView={handleChangeView}
          currentMonth={currentMonth}
          onMonthChange={handleMonthUpdate}
          minDate={minDate}
          maxDate={maxDate}
          width={rowWidth}
          height={toolbarHeight}
          {...HeaderProps}
        />
      ) : null}
      {headerHidden && !disableHeader && view !== CalendarViewTypes.year ? <Box width={rowWidth} height={headerHeight / 2} bg="transparent" /> : null}
      {<Box style={{ ...contentProps }}>{calendar}</Box>}
      {headerHidden && !disableHeader && view !== CalendarViewTypes.year ? <Box width={rowWidth} height={headerHeight / 2} bg="transparent" /> : null}
    </Box>
  );
});

Calendar.displayName = 'Calendar';
Calendar.viewTypes = CalendarViewTypes;

export { Calendar };
