export const calculateCoverageLimits = (type, coveredValue, floodSublimit = 0, eqSublimit = 0) => {
  const avgTotalInsuredValue = Number(coveredValue);
  const defaultLimit = 5000000;
  const defaultFalsePretense = 200000;
  switch (type) {
    case 'comprehensive':
    case 'collision':
    case 'hail':
    case 'terrorism':
      return avgTotalInsuredValue;
    case 'flood':
      return Math.min(avgTotalInsuredValue, floodSublimit || defaultLimit);
    case 'earthquake':
      return Math.min(avgTotalInsuredValue, eqSublimit || defaultLimit);
    case 'falsePretense':
      return Math.min(avgTotalInsuredValue, defaultFalsePretense);
    default:
      return 0;
  }
};

export const getDefaultCoverages = (quotes, data) => {
  const allCoverages = [
    { coverageType: 'comprehensive', coverageId: 6 },
    { coverageType: 'flood', coverageId: 21 },
    { coverageType: 'earthquake', coverageId: 22 },
    { coverageType: 'hail', coverageId: 9 },
    { coverageType: 'collision', coverageId: 7 },
    { coverageType: 'falsePretense', coverageId: 10 },
    { coverageType: 'terrorism', coverageId: 11 },
  ];

  const locationIndex = quotes.locations.findIndex((location) => location.id === data?.id);
  const previousIndex = locationIndex <= 0 ? quotes.locations.length - 1 : locationIndex - 1;

  const previousCoverages = quotes.locations.length > 0 ? quotes.locations[previousIndex].coverages : [];

  // Map over all coverages and update deductibles based on coverageId matching
  const updatedCoverages = allCoverages.map((coverage) => {
    const previousCoverage = previousCoverages.find((prev) => prev.coverageId === coverage.coverageId);

    return {
      ...coverage,
      locationId: data?.id || null,
      payoutLimit: calculateCoverageLimits(coverage.coverageType, data?.coveredValue) || 0,
      deductible: previousCoverage?.deductible || 0,
      aggregate: 0,
      netPremium: 0,
      isIncluded: false,
    };
  });

  return updatedCoverages;
};
