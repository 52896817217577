import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid, GridModal, Text } from '../../../ui';
import { usePolicyPageContext } from '../context/PolicyPageContext';
import { useApi, useNavigate } from '../../../hooks';
import moment from 'moment-timezone';
import { CustomDatePicker, LayoutBox } from '../../../components';

const CreateRetroMCEModal = ({ showModal, setShowModal, policyId, getPayload, createMCE }) => {
  const navigate = useNavigate();
  const { setCurrentTab = () => {} } = usePolicyPageContext();
  const [editEffectiveAt, setEditEffectiveAt] = useState(false);
  const [editEffectiveUntil, setEditEffectiveUntil] = useState(false);
  const payload = getPayload();
  const [effectiveAt, setEffectiveAt] = useState(moment.utc(payload.effectiveAt));
  const [effectiveUntil, setEffectiveUntil] = useState(moment.utc(payload.effectiveUntil));
  const [inProgress, setInProgress] = useState(false);

  const handleOnSubmit = async () => {
    setInProgress(true);

    try {
      const updatedPayload = {
        ...payload,
        effectiveAt: moment(effectiveAt).format('YYYY-MM-DD'),
        effectiveUntil: moment(effectiveUntil).format('YYYY-MM-DD'),
      };

      console.log('updatedPayload', updatedPayload);
      const response = await createMCE(policyId, updatedPayload);
      if (!response || response.length === 0) {
        alert('Could not create retrospective MCE. Please ask admin.');
      } else if (response.length === 1) {
        const mceId = response[0].id;
        navigate.to(`/policies/${policyId}/mces/${mceId}`);
      } else if (response.length > 1) {
        setCurrentTab('MCES');
        alert('Created two retrospective MCEs surrounding location change MCE.');
      } else {
        console.error('Unexpected response structure: ', response);
      }
    } catch (error) {
      console.error('Failed to create retrospective MCE:', error);
      alert('Unable to create the MCE. Please try again later.');
    } finally {
      setShowModal(false);
      setInProgress(false);
    }
  };

  return (
    <GridModal
      title={'create Retrospective MCE'}
      buttonText={'Create'}
      handlePress={handleOnSubmit}
      open={showModal}
      onClose={() => setShowModal(false)}
      width="480px"
      capitalizeTitle={true}
      loading={inProgress}
      disabled={inProgress}
    >
      <LayoutBox gap={16} padding="16px">
        <LayoutBox row alignItems="flex-start" padding="8px" paddingLeft="0px" gap={8} alignSelf="stretch" width="100%">
          <Box style={{ color: '#637381', fontSize: 16, fontWeight: 500, alignItems: 'flex-start' }} width="40%" marginY="auto">
            <Text style={{ color: '#212B36', fontSize: 16, fontWeight: 500 }}>Effective At:</Text>
          </Box>
          <Box style={{ color: '#637381', fontSize: 16, fontWeight: 500 }} width="50%">
            <CustomDatePicker
              label="Date"
              placeholder={'MM/DD/YYYY'}
              shouldShowPicker={editEffectiveAt}
              setShouldShowPicker={setEditEffectiveAt}
              setDate={setEffectiveAt}
              date={effectiveAt}
            />
          </Box>
        </LayoutBox>
        <LayoutBox row alignItems="flex-start" padding="8px" paddingLeft="0px" gap={8} alignSelf="stretch" width="100%" zIndex={-3}>
          <Box style={{ color: '#637381', fontSize: 16, fontWeight: 500, alignItems: 'flex-start' }} width="40%" marginY="auto">
            <Text style={{ color: '#212B36', fontSize: 16, fontWeight: 500 }}>Effective Until:</Text>
          </Box>
          <Box style={{ color: '#637381', fontSize: 16, fontWeight: 500 }} width="50%">
            <CustomDatePicker
              label="Date"
              placeholder={'MM/DD/YYYY'}
              shouldShowPicker={editEffectiveUntil}
              setShouldShowPicker={setEditEffectiveUntil}
              setDate={setEffectiveUntil}
              date={effectiveUntil}
            />
          </Box>
        </LayoutBox>
      </LayoutBox>
    </GridModal>
  );
};

export const MonthlyReporting = React.forwardRef((props) => {
  const { policy = [], setGridReference } = props;
  const { createMCE } = useApi();
  const { editMonthlyReporting, setMonthlyReportingData, monthlyReportingData } = usePolicyPageContext();
  const [rowData, setRowData] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [showModal, setShowModal] = useState(false);
  let updateTimeout = null;

  const handleCheckboxChange = (e, columnId) => {
    const columnIndex = Number(columnId.split('_')[1]);

    if (e.target.checked) {
      setSelectedColumns((prevColumns) => [...prevColumns, columnIndex]);
    } else {
      setSelectedColumns((prevColumns) => prevColumns.filter((index) => index !== columnIndex));
    }
  };

  const formatPayload = useCallback(
    (columnIndex) => {
      const payload = {
        months: [monthlyReportingData?.months[columnIndex]],
      };

      return payload;
    },
    [monthlyReportingData]
  );

  const sumValues = (values) => {
    return values.reduce((sum, value) => {
      const numericValue = typeof value === 'string' && !isNaN(parseFloat(value)) ? parseFloat(value) : value;
      if (typeof numericValue === 'number') {
        return sum + numericValue;
      }
      return sum;
    }, 0);
  };

  const getPayload = useCallback(() => {
    const payloads = selectedColumns.map((index) => {
      const payload = formatPayload(index);
      return payload;
    });

    const combinedPayload = {};

    payloads.forEach((payload) => {
      Object.keys(payload).forEach((key) => {
        if (!combinedPayload[key]) {
          combinedPayload[key] = [];
        }
        combinedPayload[key] = combinedPayload[key].concat(payload[key]);
      });
    });
    const sortedMonths = combinedPayload?.months?.sort((a, b) => new Date(a) - new Date(b));

    const finalPayload = {
      type: 'retrospective',
      effectiveAt: sortedMonths[0],
      effectiveUntil: sortedMonths[sortedMonths.length - 1],
    };

    return finalPayload;
  }, [selectedColumns, formatPayload]);

  const createColumns = () => {
    const baseColumns = [
      {
        headerName: 'Declared Locations',
        children: [
          { headerName: 'RiskId', field: 'riskId', minWidth: 100 },

          { headerName: 'Name', field: 'name', minWidth: 150 },
          {
            headerName: 'Address',
            field: 'address',
            minWidth: 250,
            cellClassRules: {
              'blue-background': (params) => {
                return [
                  '% of Bound',
                  'Total',
                  'Date Reported',
                  'Preparer',
                  'File Type',
                  'Understory Checked',
                  'Understory Signed Off',
                  'Include in MCE',
                ].includes(params.data.address);
              },
            },
            cellRenderer: (params) => {
              if (params.data.address === 'Create MCE') {
                return (
                  <Box border={'1px solid #707E8A'} borderRadius={8} marginTop="$2" marginBottom="$2">
                    <Button
                      color={'$primary'}
                      variant="text"
                      label={'Create Retroactive MCE'}
                      paddingX={8}
                      paddingY={4}
                      onPress={() => {
                        if (!selectedColumns || selectedColumns.length === 0) {
                          alert('Please select month.');
                        } else {
                          setShowModal(true);
                        }
                      }}
                      minHeight={50}
                    />
                  </Box>
                );
              }

              return params.value !== undefined ? params.value : ''; // Avoid blank cells
            },
            cellStyle: (params) => {
              if (params.data.address === 'Create MCE') {
                return { textAlign: 'center' };
              }
              return null;
            },
          },
          {
            headerName: 'Bound TIV',
            field: 'boundTiv',
            minWidth: 150,
            valueFormatter: (params) => {
              if (!params.data.formatter || typeof params.value === 'string') return;
              if (params.data.address === '% of Bound') {
                return params.value ? `${params.value.toFixed(2)}%` : '';
              }
              return params.value ? `$${params.value.toLocaleString()}` : '';
            },

            cellStyle: (params) => {
              if (params.data.type === 'label' || typeof params.data.boundTiv === 'string') {
                return {
                  border: '1px solid black',
                  fontWeight: 'bold',
                };
              } else if (params.data.address === 'Total') {
                return {
                  fontWeight: 'bold',
                };
              }
              return null;
            },
          },
        ],
      },
    ];

    const monthlyColumns =
      monthlyReportingData && monthlyReportingData.months
        ? monthlyReportingData.months.map((date, index) => {
            return {
              headerName: date,
              children: [
                {
                  headerName: 'Declared Inventory Value',
                  field: `monthlyInventory_${index}`,
                  minWidth: 150,
                  valueFormatter: (params) => {
                    if (!params.data.formatter || typeof params.value === 'string') return;
                    if (params.data.address === '% of Bound') {
                      return params.value ? `${params.value.toFixed(2)}%` : '';
                    }
                    return params.value ? `$${params.value.toLocaleString()}` : '';
                  },

                  cellRenderer: (params) => {
                    const columnIndex = params.column.getColId(); // Get the column index

                    if (params.data.address === 'Include in MCE') {
                      return (
                        <input
                          type="checkbox"
                          style={{
                            width: '20px',
                            height: '20px ',
                            cursor: `${
                              monthlyReportingData.uwSignedOff[Number(columnIndex.split('_')[1])] || !editMonthlyReporting ? 'pointer' : 'not-allowed'
                            }`,
                          }}
                          onChange={(e) => handleCheckboxChange(e, columnIndex)}
                          disabled={!monthlyReportingData.uwSignedOff[Number(columnIndex.split('_')[1])] || !editMonthlyReporting}
                          checked={selectedColumns.includes(Number(columnIndex.split('_')[1]))} // Check if the extracted index is selected
                        />
                      );
                    } else if (typeof params.value === 'string') return params.value;
                    else if (params.data.address === '% of Bound') {
                      return params.value ? `${params.value.toFixed(2)}%` : '';
                    }
                    return params.value ? `$${params.value.toLocaleString()}` : '';
                  },
                  cellEditorSelector: (params) => {
                    if (params.data.address === 'File Type') {
                      return {
                        component: 'agRichSelectCellEditor',
                        params: {
                          values: ['File Type', 'Spreadsheet', 'Document', 'PDF', 'Image'],
                        },
                      };
                    } else if (
                      ['Preparer', 'Understory Checked', 'Understory Signed Off'].includes(params.data.address) ||
                      params.data.type === 'monthlyInventory'
                    ) {
                      return { component: 'agTextCellEditor' };
                    } else if (params.data.address === 'Date Reported') {
                      return {
                        component: 'agDateCellEditor',
                        params: {
                          dateFormat: 'YYYY-MM-DD',
                        },
                      };
                    }
                    return null;
                  },
                  editable: (params) => {
                    return (
                      (params.data.type === 'monthlyInventory' ||
                        ['File Type', 'Date Reported', 'Preparer', 'Understory Checked', 'Understory Signed Off'].includes(params.data.address)) &&
                      editMonthlyReporting
                    );
                  },
                  cellClassRules: {
                    'ag-cell-editable': (params) =>
                      (editMonthlyReporting && params.data.type === 'monthlyInventory') ||
                      (['File Type', 'Date Reported', 'Preparer', 'Understory Checked', 'Understory Signed Off', 'Include in MCE'].includes(
                        params.data.address
                      ) &&
                        editMonthlyReporting),
                  },
                  valueSetter: (params) => {
                    let value = params.newValue;
                    const colId = params.colDef.field;
                    const valueIndex = parseInt(colId.match(/\d+/)[0]);
                    let updatedData = { ...monthlyReportingData };
                    if (params.data.type === 'dateReported') {
                      let dateValue;
                      if (typeof params.newValue === 'string') {
                        dateValue = new Date(params.newValue);
                      } else if (params.newValue instanceof Date) {
                        dateValue = params.newValue;
                      } else {
                        return false;
                      }

                      if (!isNaN(dateValue.getTime())) {
                        const year = dateValue.getFullYear();
                        const month = String(dateValue.getMonth() + 1).padStart(2, '0');
                        const day = String(dateValue.getDate()).padStart(2, '0');
                        updatedData[params.data.type][valueIndex] = `${year}-${month}-${day}`;
                        params.data[`monthlyInventory_${valueIndex}`] = `${year}-${month}-${day}`;
                      }
                    } else if (params.data.type !== 'monthlyInventory') {
                      updatedData[params.data.type][valueIndex] = value;
                      params.data[`monthlyInventory_${valueIndex}`] = value;
                    } else {
                      value = isNaN(parseInt(value)) ? value : parseInt(value); // Ensure value is an integer
                      const riskId = params.data.riskId;
                      const location = updatedData.locations.find((loc) => loc.riskId === riskId);

                      if (location) {
                        location.values[valueIndex] = value || null;
                        params.data[`monthlyInventory_${valueIndex}`] = value;
                      }
                    }
                    if (updateTimeout) clearTimeout(updateTimeout);

                    // Set a timeout to update the context after a delay
                    updateTimeout = setTimeout(() => {
                      setMonthlyReportingData(updatedData); // Update context with all changes
                      updateTimeout = null; // Clear timeout reference
                    }, 100);
                    // Return true to indicate the value was set successfully
                    return true;
                  },

                  cellStyle: (params) => {
                    if (
                      params.data.type === 'label' ||
                      ['Declared Inventory', 'Bound Inventory', 'Difference', 'Rate', 'Premium Due'].includes(params.data.boundTiv) ||
                      typeof params.data.boundTiv === 'string'
                    ) {
                      return {
                        border: '1px solid black',
                        fontWeight: 'bold',
                      };
                    } else if (params.data.address === 'Total') {
                      return {
                        fontWeight: 'bold',
                      };
                    }
                    return null;
                  },
                  cellDataType: 'string',
                },
              ],
            };
          })
        : [];

    return [...baseColumns, ...monthlyColumns];
  };

  const mapRowData = useCallback(() => {
    if (!monthlyReportingData || !monthlyReportingData.locations || !monthlyReportingData.months) {
      return [];
    }
    const baseRowData = policy?.locations
      ?.map((location) => {
        const matchingLocation = monthlyReportingData.locations.find((dummyLoc) => dummyLoc.riskId === location.riskId);
        const monthlyValues = matchingLocation
          ? monthlyReportingData.months.reduce((acc, _, index) => {
              acc[`monthlyInventory_${index}`] = isNaN(parseInt(matchingLocation.values?.[index]))
                ? matchingLocation.values?.[index]
                : parseInt(matchingLocation.values?.[index]) || null; // Default to 0 if undefined
              return acc;
            }, {})
          : {};

        return {
          name: location.name,
          address: location.address,
          formatter: true,
          type: 'monthlyInventory',
          riskId: location.riskId,
          boundTiv: location.coveredValue,
          ...monthlyValues,
        };
      })
      .sort((locA, locB) => {
        const riskIdA = locA.riskId || Number.MAX_SAFE_INTEGER;
        const riskIdB = locB.riskId || Number.MAX_SAFE_INTEGER;

        return riskIdA - riskIdB;
      });
    const totalRow = {
      address: 'Total',
      formatter: true,
      boundTiv: sumValues(baseRowData?.map((row) => row.boundTiv)),
      ...monthlyReportingData.months.reduce((acc, _, index) => {
        acc[`monthlyInventory_${index}`] = sumValues(baseRowData.map((row) => row[`monthlyInventory_${index}`] || 0));
        return acc;
      }, {}),
    };

    const percentageRow = {
      address: '% of Bound',
      formatter: true,
      boundTiv: totalRow.boundTiv ? (totalRow.boundTiv / totalRow.boundTiv) * 100 : 0,
      ...monthlyReportingData.months.reduce((acc, _, index) => {
        acc[`monthlyInventory_${index}`] = totalRow.boundTiv ? ((totalRow[`monthlyInventory_${index}`] || 0) / totalRow.boundTiv) * 100 : 0;
        return acc;
      }, {}),
    };

    const checkBoxRow = {
      address: 'Include in MCE',
      formatter: false,
      ...monthlyReportingData.months.reduce((acc, _, index) => {
        acc[`monthlyInventory_${index}`] = false;
        return acc;
      }, {}),
    };

    const additionalRows = [
      {
        address: 'Date Reported',
        boundTiv: null,
        formatter: false,
        type: 'dateReported',
        ...monthlyReportingData.months.reduce((acc, _, index) => {
          acc[`monthlyInventory_${index}`] = monthlyReportingData.dateReported?.[index] || null; // Default to null if undefined
          return acc;
        }, {}),
      },
      {
        address: 'Preparer',
        boundTiv: null,
        type: 'preparer',
        formatter: false,
        ...monthlyReportingData.months.reduce((acc, _, index) => {
          acc[`monthlyInventory_${index}`] = monthlyReportingData.preparer?.[index] || null;
          return acc;
        }, {}),
      },
      {
        address: 'File Type',
        boundTiv: null,
        formatter: false,
        type: 'fileType',
        ...monthlyReportingData.months.reduce((acc, _, index) => {
          acc[`monthlyInventory_${index}`] = monthlyReportingData.fileType?.[index] || null;
          return acc;
        }, {}),
      },
      {
        address: 'Create MCE',
      },
      {},
      {
        address: 'Understory Checked',
        boundTiv: null,
        formatter: false,
        type: 'uwChecked',
        ...monthlyReportingData.months.reduce((acc, _, index) => {
          acc[`monthlyInventory_${index}`] = monthlyReportingData.uwChecked?.[index] || null;
          return acc;
        }, {}),
      },
      {
        address: 'Understory Signed Off',
        boundTiv: null,
        formatter: false,
        type: 'uwSignedOff',
        ...monthlyReportingData.months.reduce((acc, _, index) => {
          acc[`monthlyInventory_${index}`] = monthlyReportingData.uwSignedOff?.[index] || null;
          return acc;
        }, {}),
      },
    ];

    const monthLabelRow = {
      type: 'label',
      ...monthlyReportingData.months.reduce((acc, date, index) => {
        const monthName = new Date(date).toLocaleString('default', { month: 'long' });
        acc[`monthlyInventory_${index}`] = monthName;
        return acc;
      }, {}),
      cellStyle: { border: '1px solid black' },
    };

    const summaryRows = [
      {
        boundTiv: 'Declared Inventory',
        formatter: true,
        ...monthlyReportingData.months.reduce((acc, _, index) => {
          acc[`monthlyInventory_${index}`] = monthlyReportingData.declaredInventory?.[index] || null; // Default to null if undefined
          return acc;
        }, {}),
      },
      {
        boundTiv: 'Bound Inventory',
        formatter: true,
        ...monthlyReportingData.months.reduce((acc, _, index) => {
          acc[`monthlyInventory_${index}`] = monthlyReportingData.boundInventory?.[index] || null; // Default to null if undefined
          return acc;
        }, {}),
      },
      {
        boundTiv: 'Difference',
        formatter: true,
        ...monthlyReportingData.months.reduce((acc, _, index) => {
          acc[`monthlyInventory_${index}`] = monthlyReportingData.difference?.[index] || null; // Default to null if undefined
          return acc;
        }, {}),
      },
      {
        boundTiv: 'Rate',
        formatter: true,
        ...monthlyReportingData.months.reduce((acc, _, index) => {
          acc[`monthlyInventory_${index}`] = monthlyReportingData.rate?.[index] || null; // Default to null if undefined
          return acc;
        }, {}),
      },
      {
        boundTiv: 'Premium Due',
        formatter: true,
        ...monthlyReportingData.months.reduce((acc, _, index) => {
          acc[`monthlyInventory_${index}`] = monthlyReportingData.premiumDue?.[index] || null; // Default to null if undefined
          return acc;
        }, {}),
      },
    ];

    return [...baseRowData, {}, totalRow, percentageRow, checkBoxRow, {}, ...additionalRows, monthLabelRow, ...summaryRows];
  }, [monthlyReportingData, policy]);

  const getRowHeight = (params) => {
    if (params.data.address === 'Create MCE') {
      return 80;
    }
  };

  useEffect(() => {
    policy.locations.length && setRowData(mapRowData());
  }, [policy, mapRowData]);

  return (
    <div className="ag-theme-quartz" style={{ minHeight: '500px' }}>
      <Grid
        data={rowData}
        columns={monthlyReportingData && createColumns()}
        floatingFilter={false}
        setGridReference={setGridReference}
        shouldShowPagination={false}
        getRowHeight={getRowHeight}
      />
      {showModal && (
        <CreateRetroMCEModal showModal={showModal} setShowModal={setShowModal} policyId={policy.id} getPayload={getPayload} createMCE={createMCE} />
      )}
    </div>
  );
});
