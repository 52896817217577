import React, { useCallback, useMemo } from 'react';
import { Box, Button, Grid } from '../../../ui';
import { useMCEPageContext } from '../context/MCEPageContext';
import { LayoutBox } from '../../../components';
import { v4 as uuid } from 'uuid';
import { currencyFormatter } from '../../QuotePage/content/QuoteGrids/utils';

export const MCEFees = React.memo((props, ref) => {
  const { setGridReference, onSelectionChanged, gridReference } = props;
  const { MCEDetails, isEditing, setMCEDetails } = useMCEPageContext();

  const calculateAmount = (rateOfNet, totalGrossPremium) => {
    return rateOfNet ? (Number(rateOfNet) / 100) * Number(totalGrossPremium) : 0;
  };
  const formatPercentage = (value) => {
    if (value === 0 || isNaN(value)) {
      return 'N/A';
    }
    const formattedValue = Number(value).toFixed(4);
    // Check if the formatted value is '0.00' but the actual value is non-zero
    if (formattedValue === '0.00' && value > 0) {
      return '< 0.01%';
    }
    return `${formattedValue}%`;
  };

  const setGridValues = useCallback(
    (params, MCEDetails, totalGrossPremium) => {
      const { data, newValue } = params;
      const { id } = data;
      const allFees = MCEDetails.fees;
      const editedFieldMap = {
        fee: 'name',
        annual: 'amount',
        percentageOfPremium: 'rateOfNet',
      };
      const editedField = editedFieldMap[params.colDef.field] || '';
      const rowIndex = allFees.findIndex((fee) => fee.id === id);
      const value = params.colDef.dataType === 'number' ? parseFloat(newValue) : newValue;
      if (rowIndex === -1) return;
      console.log('allFees', allFees, rowIndex, value);
      allFees[rowIndex][editedField] = isNaN(value) && editedField !== 'name' ? 0 : value;
      const updatedFee = { ...allFees[rowIndex] };
      if (editedField === 'amount') {
        updatedFee.rateOfNet = null;
      } else if (editedField === 'rateOfNet') {
        const newAmount = !isNaN(value) ? Number(calculateAmount(value, totalGrossPremium).toFixed(2)) : updatedFee.amount;
        if (value && !isNaN(value)) {
          updatedFee.amount = newAmount;
        }
      } else if (updatedFee[editedField]) updatedFee[editedField] = value;
      setMCEDetails((prev) => {
        const updatedFees = [...prev.fees];
        updatedFees[rowIndex] = updatedFee;
        return { ...prev, fees: updatedFees };
      });
    },
    [setMCEDetails]
  );

  const colDefs = useMemo(() => {
    return [
      {
        headerName: 'Summary of Costs',
        field: 'fee',
        width: 250,
        editable: (params) => params.data.isEditable && isEditing,
        cellClassRules: {
          'ag-cell-editable': (params) => params.data.isEditable && isEditing,
        },
        valueSetter: (params) => {
          setGridValues(params, MCEDetails);
          return true;
        },
        minWidth: 300,
        cellRendererParams: { suppressCount: true },
        headerCheckboxSelection: isEditing,
        checkboxSelection: (params) => params.data?.isEditable && isEditing,
      },
      {
        headerName: 'Percentage of Premium',
        field: 'percentageOfPremium',
        cellClassRules: {
          'ag-cell-editable': (params) => params.data.isEditable && isEditing,
        },
        editable: (params) => params.data.isEditable && isEditing,
        valueFormatter: (params) => (params.data ? formatPercentage(params.data.percentageOfPremium) : ''),
        valueSetter: (params) => {
          const oldValue = params.data[params.colDef.field];
          if (oldValue === params.newValue) return false;
          params.api.flashCells({
            rowNodes: [params.node],
            columns: ['annual', 'percentageOfPremium'],
          });
          setGridValues(params, MCEDetails, MCEDetails.netPremium);
          return true;
        },
      },
      {
        headerName: 'Cost',
        field: 'annual',
        editable: (params) => params.data.isEditable && isEditing,
        valueFormatter: (params) => (params.value ? currencyFormatter({ field: { value: params.value }, withDecimals: true }) : 'N/A'),
        cellClassRules: {
          'ag-cell-editable': (params) => params.data.isEditable && isEditing,
        },
        valueSetter: (params) => {
          const oldValue = params.data[params.colDef.field];
          if (oldValue === params.newValue) return false;
          params.api.flashCells({
            rowNodes: [params.node],
            columns: ['annual', 'percentageOfPremium'],
          });
          setGridValues(params, MCEDetails, MCEDetails.netPremium);
          return true;
        },
      },
    ];
  }, [isEditing, MCEDetails, setGridValues]);

  const feeRows = MCEDetails?.fees
    ? MCEDetails?.fees?.map((fee) => ({
        fee: fee.name,
        percentageOfPremium: fee?.rateOfNet || 0,
        annual: fee.amount,
        isEditable: true,
        id: fee.id,
      }))
    : [];

  const totalCost = MCEDetails?.netPremium + feeRows?.reduce((sum, row) => sum + row.annual, 0);
  const totalPremium = feeRows?.reduce((sum, row) => sum + row.percentageOfPremium, 0);

  const rowData = [
    { fee: 'Premium', percentageOfPremium: 100, annual: MCEDetails?.netPremium },
    ...feeRows,
    { fee: 'Total', percentageOfPremium: totalPremium, annual: totalCost, isEditable: false },
  ];

  const handlePress = () => {
    const newRow = {
      id: uuid(),
      name: 'New Fee',
      amount: 0,
      rateOfNet: 0,
    };
    setMCEDetails((prev) => {
      const updatedFees = [...prev.fees, newRow];
      return { ...prev, fees: updatedFees };
    });
    gridReference.api.applyTransaction({ add: [newRow] });
  };

  const onCellValueChanged = (params) => {
    params.api.flashCells({
      rowNodes: [params.node],
      columns: [params.column],
    });
  };

  return (
    <div className="ag-theme-quartz" style={{ marginBottom: '10px', width: '100%' }}>
      <Grid
        data={rowData}
        columns={colDefs}
        floatingFilter={false}
        setGridReference={setGridReference}
        onSelectionChanged={onSelectionChanged}
        shouldShowPagination={false}
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={100}
        onCellValueChanged={onCellValueChanged}
        rowSelection="multiple"
      />
      {isEditing && (
        <LayoutBox row justifyContent="space-between" width="full" backgroundColor="white">
          <Box display="flex" alignItems="start" justifyContent="center" flexWrap="wrap" height={70}>
            <Box border="1px solid #919EAB52" marginLeft="$2" borderRadius={8}>
              <Button color="#000" variant="text" onPress={handlePress}>
                Add Fees
              </Button>
            </Box>
          </Box>
        </LayoutBox>
      )}
    </div>
  );
});
