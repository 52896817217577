import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';

export const AlpineThemeGrid = ({
  setGridReference,
  columnDefs,
  rowData,
  onCellEditingStopped,
  onCellEditingStarted,
  headerHeight,
  headerBgColor,
}) => {
  const onGridReady = (params) => {
    setGridReference(params);
  };

  return (
    <div className={`ag-theme-alpine ${headerBgColor}`} style={{ width: '100%', maxHeight: '500px' }}>
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        modules={[ExcelExportModule]}
        onGridSizeChanged={(params) => {
          params.api.sizeColumnsToFit();
        }}
        onGridReady={onGridReady}
        onCellEditingStopped={onCellEditingStopped}
        onCellEditingStarted={onCellEditingStarted}
        headerHeight={headerHeight}
      />
    </div>
  );
};
