import React, { useMemo, useRef } from 'react';
import moment from 'moment-timezone';
import { Box, Text, Heading } from '../../ui';
import { withStyles } from '../../ui/styling';
import { capitalize, uniqueId } from '../../ui/utils';
import {
  TextData,
  DataListRenderer,
  Main,
  ColumnsAndSettingsProvider,
  ItemRow,
  ITEM_ROW_HEIGHT,
  ListSearchControls,
  ProvideSearchAPIContext,
  ListSearchDataRenderer,
} from '../../components';
import { useApi, useNavigate, useAddProductOptionsToSearchOptions } from '../../hooks';
import { ListDisplayTypes } from '../../constants';
import { apiHelpers, formatMoney, getAddressLines } from '../../utils';
import { NULL_STYLE } from '../../ui/system';
import { useEventCallback } from '../../ui/hooks';
import { policySearchOptions } from '../../constants/searchData/searchOptions';

const FILTERS_BAR_HEIGHT = 55;

const defaultColDefs = [
  {
    options: ['product.name', 'carrier.name'],
    label: ['PRODUCT', 'CARRIER'],
    field: 'product.name',
    isRequired: false,
    dataType: 'string',
    headerName: 'PRODUCT',
    id: uniqueId(),
  },
  {
    options: ['insured', 'agent.name', 'broker.name'],
    label: ['INSURED', 'AGENT', 'BROKER'],
    field: 'insured',
    isRequired: false,
    dataType: 'string',
    id: uniqueId(),
    headerName: 'INSURED',
    cellStyle: { color: '#637381', fontWeight: 'bold', fontSize: '14px' },
  },
  {
    options: ['region', 'address', 'country'],
    label: ['REGION', 'STREET ADDRESS', 'COUNTRY'],
    field: 'region',
    isRequired: false,
    dataType: 'string',
    headerName: 'REGION',
    id: uniqueId(),
    availableFormatters: {
      address: ({ data: { address = 'N/A' } }) => address,
      region: ({ data: { region, postcode } }) => `${region || 'N/A'}${postcode && region ? ', ' : ''}${postcode || ''}`,
      country: ({ data: { countryCode, region } }) => `${region || 'N/A'}${countryCode && region ? ', ' : ''}${countryCode || ''}`,
    },
    valueFormatter: ({ data: { region, postcode } }) => `${region || 'N/A'}${postcode && region ? ', ' : ''}${postcode || ''}`,
  },
  {
    options: ['coveredValue', 'netPremium', 'numLocations', 'boundAt'],
    label: ['COVERED VALUE', 'PREMIUM', 'NUM. LOCATIONS', 'BOUND AT'],
    field: 'coveredValue',
    isRequired: false,
    dataType: 'string',
    headerName: 'COVERED VALUE',
    id: uniqueId(),
    availableFormatters: {
      coveredValue: ({ data: { coveredValue } }) => (coveredValue ? `$${formatMoney(coveredValue)}` : 'N/A'),
      netPremium: ({ data: { netPremium } }) => (netPremium ? `$${formatMoney(netPremium)}` : 'N/A'),
      numLocations: ({ data: { numLocations } }) => `${numLocations} Locations`,
      boundAt: ({ data: { boundAt } }) => (boundAt ? moment(boundAt).format('MM-DD-YYYY') : 'N/A'),
    },
    valueFormatter: ({ data: { coveredValue } }) => (coveredValue ? `$${formatMoney(coveredValue)}` : 'N/A'),
  },
  {
    field: 'effectiveAt',
    headerName: 'START DATE',
    isRequired: false,
    dataType: 'string',
    id: uniqueId(),
    valueFormatter: ({ data: { effectiveAt } }) => (effectiveAt ? moment(effectiveAt).format('MM-DD-YYYY') : 'N/A'),
  },
  {
    field: 'effectiveUntil',
    headerName: 'END DATE',
    isRequired: false,
    dataType: 'string',
    id: uniqueId(),
    valueFormatter: ({ data: { effectiveUntil } }) => (effectiveUntil ? moment(effectiveUntil).format('MM-DD-YYYY') : 'N/A'),
  },
  {
    field: 'insured',
    headerName: 'ACTIVE',
    isRequired: false,
    dataType: 'string',
    id: uniqueId(),
    cellRenderer: ({ data }) => <ActiveStatus payoutsEnabled={data.payoutsEnabled} />,
  },
];
export const PoliciesListView = ({ data = undefined, searchPath, onSelect = undefined, ...rest }) => {
  const listRef = useRef();
  const { getPolicies } = useApi();
  const navigate = useNavigate();

  const navigateToPolicy = React.useCallback(
    (policy) => {
      if (policy) {
        navigate.to(`/policies/${policy.id}`);
      }
    },
    [navigate]
  );

  const renderHeader = useMemo(
    () => (renderProps) => {
      return <ListSearchControls heading="Policies" searchPlaceholder="Search for policies" {...renderProps} />;
    },
    []
  );

  const searchOptions = useAddProductOptionsToSearchOptions(policySearchOptions);
  return (
    <Main maxWidth={'100%'} paddingX="120px" style={{ backgroundColor: 'white' }}>
      <ProvideSearchAPIContext
        data={data}
        searchPath={searchPath}
        searchOptions={searchOptions}
        getData={getPolicies}
        appSettingsKey="PoliciesListView"
        cacheKey="policylist"
        listRef={listRef}
      >
        <ColumnsAndSettingsProvider viewKey="PoliciesListView">
          <ListSearchDataRenderer
            ref={listRef}
            onSelect={onSelect !== undefined ? onSelect : navigateToPolicy}
            emptyMessage="No policies to show"
            renderHeader={renderHeader}
            tableItemComponent={PolicyRowItem}
            cardItemComponent={PolicyListItem}
            defaultColDefs={defaultColDefs}
            stickyHeaderEnabled
            scrollNode
            {...rest}
          />{' '}
        </ColumnsAndSettingsProvider>
      </ProvideSearchAPIContext>
    </Main>
  );
};

// item xs: 296 and 316, sm: 186
export const PolicyList = React.forwardRef((props, ref) => {
  const { styles, displayType = ListDisplayTypes.CARDS, ...rest } = props;
  const Component = displayType === ListDisplayTypes.TABLE ? PolicyRowItem : PolicyListItem;
  return (
    <DataListRenderer
      ref={ref}
      itemComponent={Component}
      emptyMessage="No policies to show"
      displayType={displayType}
      itemPath="/policies"
      styles={[
        {
          root: {
            minHeight: 400,
            padTop: ({ theme }) => theme.sizes.appBarHeight + theme.spacing(1),
          },
          item: {
            height: displayType === ListDisplayTypes.CARDS ? ({ theme }) => theme.breakpoints({ xs: 312, sm: 186 }) : ITEM_ROW_HEIGHT,
            mb: displayType === ListDisplayTypes.CARDS ? '$3' : 0,
          },
          listLoading: {
            justifyContent: 'center',
          },
          header: {
            height: ({ theme }) =>
              theme.breakpoints({
                xs: 186 + theme.sizes.appBarHeight + theme.spacing(1),
                sm: 154 + theme.sizes.appBarHeight + FILTERS_BAR_HEIGHT + (displayType === ListDisplayTypes.TABLE ? ITEM_ROW_HEIGHT : 0),
              }),
          },
        },
        styles,
      ]}
      {...rest}
    />
  );
});

const PolicyRowItem = React.forwardRef(function PolicyRowItem(props, ref) {
  const { item, styles, onPress, ...rest } = props;
  const handleOnPress = useEventCallback(() => {
    onPress(item);
  });
  const policy = useFormatPolicyData(item);

  return <ItemRow ref={ref} item={policy} onPress={onPress ? handleOnPress : undefined} {...rest} />;
});

function useFormatPolicyData(policy = {}) {
  const { product: p, carrier, address, region, postcode, agent, broker } = policy;

  const product = useMemo(() => {
    return apiHelpers.resolveProduct(p);
  }, [p]);

  const addressLines = getAddressLines(address, region, postcode);

  const agentName = agent && agent.name ? agent.name.split('@')[0] : agent && agent.email ? agent.email.split('@')[0] : 'N/A';
  const brokerName = broker && broker.name ? broker.name : 'N/A';
  const carrierName = carrier && carrier.name ? carrier.name : 'N/A';
  return {
    ...policy,
    agent: {
      ...agent,
      name: agentName,
    },
    broker: {
      ...broker,
      name: brokerName,
    },
    carrier: {
      ...carrier,
      name: carrierName,
    },
    product,
    addressLines,
  };
}

export const PolicyListItem = withStyles(({ theme }) => ({
  root: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignSelf: 'stretch',
    borderRadius: 6,
    borderWidth: 1,
    borderColor: theme.colors.opacity('$gray.300', 0.3),
    px: theme.spacing(3),
    py: theme.spacing(2.75),
    bg: theme.colors.white,
  },
  progressOverlay: {
    ...theme.layout.absoluteFill,
    bottom: 0,
    top: null,
    height: 3,
    borderRadius: 6,
  },
  topContainer: {
    flexDirection: theme.breakpoints({ xs: 'column', sm: 'row' }),
    justifyContent: theme.breakpoints({ xs: 'flex-start', sm: 'space-between' }),
  },
  statusChips: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
    padBottom: theme.spacing(1),
    padTop: theme.spacing(0.5),
    props: {
      gap: 8,
    },
  },
  contentLeft: {
    flex: theme.breakpoints({ xs: NULL_STYLE, sm: 1.25 }),
    props: {
      gap: 0,
    },
  },
  contentRight: {
    flex: theme.breakpoints({ xs: NULL_STYLE, sm: 0.75 }),
    justifyContent: 'flex-start',
    alignItems: theme.breakpoints({ xs: 'flex-start', sm: 'flex-end' }),
    props: {
      gap: 12,
    },
    padTop: theme.spacing(1),
  },
  bottomContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flex: 1,
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    padTop: theme.spacing(1.5),
  },
  bottomSpacing: {
    flexGrow: 0.1,
    flexShrink: 0,
    flexBasis: '10%',
  },
  bottomContent: {
    flexGrow: 0.9,
    flexShrink: 0,
    flexBasis: 'auto',
  },
}))(
  React.forwardRef(function PolicyListItem(props, ref) {
    const { item: data, styles, onPress, ...rest } = props;
    const { insured, product, addressLines, effectiveAt, effectiveUntil, locations = [], payoutsEnabled } = useFormatPolicyData(data);

    const handleOnPress = useEventCallback(() => {
      onPress(data);
    });

    return (
      <Box ref={ref} onPress={onPress ? handleOnPress : undefined} {...rest}>
        <Box style={styles.topContainer} {...styles.props.topContainer}>
          <Box style={styles.contentLeft} {...styles.props.contentLeft}>
            <ActiveStatus payoutsEnabled={payoutsEnabled} />
            <Heading level={4} maxLines={1}>
              {capitalize(insured)}
            </Heading>
            {Array.isArray(addressLines) ? (
              addressLines.map((a, i) => (
                <Text small key={`aline${i}`} maxLines={1}>
                  {a}
                </Text>
              ))
            ) : (
              <Text small maxLines={2}>
                {addressLines}
              </Text>
            )}
            <Box flex={1} justifyContent="flex-end">
              <Text small bold uppercase>
                {product.labels.name}
              </Text>
            </Box>
          </Box>
          <Box style={styles.contentRight} {...styles.props.contentRight}>
            <Text large bold>{`${locations.length} Location${locations.length > 1 ? 's' : ''}`}</Text>
            <TextData label="Effective From" data={moment(effectiveAt).format('MM-DD-YYYY')} alignItems="inherit" size="small" />
            <TextData label="Effective Until" data={moment(effectiveUntil).format('MM-DD-YYYY')} alignItems="inherit" size="small" opacity={0.8} />
          </Box>
        </Box>
      </Box>
    );
  })
);
const ActiveStatus = ({ payoutsEnabled }) => {
  return (
    <Box flexDirection="row" alignItems="center" gap={12} paddingY="23px">
      <Box size="10" bg={payoutsEnabled ? '$green' : '$coral.bright'} borderRadius="$circle" />
      <Text size="small" color={payoutsEnabled ? '$green' : '$coral.bright'} style={{ fontWeight: 600 }} padTop={2} uppercase>
        {payoutsEnabled ? 'Active' : 'Inactive'}
      </Text>
    </Box>
  );
};

export default PoliciesListView;
