import { CrossIcon } from '../../../components/icons';
import { Modal, Box, Text, Button } from '../../index';
import { withStyles } from '../../styling';

export const GridModal = withStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '$white',
    flexWrap: 'wrap',
    textAlign: 'center',
    borderRadius: 8,
  },
  text: {
    border: {
      bottom: { width: 1, color: '$gray.200' },
    },
    display: 'flex',
    justifyContent: 'space-between',
    padding: 16,
    flexDirection: 'row',
  },
  title: {
    fontSize: '18px',
    fontWeight: '700',
  },
  button: {
    padding: 16,
    borderTopWidth: '2px',
    borderTopStyle: 'solid',
    borderTopColor: 'gray',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    border: {
      top: { width: 1, color: '$gray.200' },
    },
  },
}))(function GridModal(props) {
  const {
    open,
    onClose,
    children,
    styles,
    title,
    buttonText,
    buttonRadius,
    showSecondaryButton,
    secondaryButtonText,
    secondaryButtonHandlePress,
    deleteModal,
    width,
    disabled,
    handlePress = () => {},
    loading,
    capitalizeTitle,
    cancelButtonText = 'Cancel',
    onCancel,
  } = props;
  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropProps={{
        bg: ({ theme }) => theme.colors.alpha('$gray', 0.7),
      }}
    >
      <Box style={styles.root} width={width ? width : '480px'}>
        <Box style={styles.text}>
          <Text align="left" size="large" style={styles.title} capitalize={capitalizeTitle}>
            {title}
          </Text>
          <CrossIcon onPress={onClose} />
        </Box>
        {children}
        <Box style={styles.button} zIndex={-1}>
          <Button variant="text" label={cancelButtonText} color="$primary" onPress={onCancel || onClose} />
          {showSecondaryButton && (
            <Button
              disabled={disabled}
              variant="outlined"
              label={secondaryButtonText}
              color={deleteModal ? '#FE0300' : '$primary'}
              onPress={secondaryButtonHandlePress}
              borderRadius={buttonRadius}
              marginRight="8px"
              bold={true}
            />
          )}
          <Button
            disabled={disabled}
            label={buttonText}
            color={deleteModal ? '#FE0300' : '$primary'}
            onPress={handlePress}
            borderRadius={buttonRadius}
            loading={loading}
          />
        </Box>
      </Box>
    </Modal>
  );
});
