import React, { useState, useEffect } from 'react';
import { Button, Text, TextInput } from '../../../../ui';
import { LayoutBox } from '../../../../components';
import { GridModal, RadioGroup, Radio, Box } from '../../../../ui';
import { NoEndorsmentIcon, TrashBinIconSmall, TrashBinIconLarge, EditIcon, ExpandArrowIcon, ForwardArrowIcon } from '../../../../components/icons';
import { AlpineThemeGrid } from '../../../../ui/components/Grid';
import { currencyFormatter } from './utils';
import { useAdjustmentState } from '../../context/QuoteAdjustment/AdjustmentContext';

const UpdateNamedEndorsementModal = ({
  modalState,
  setModalState,
  targetModalName,
  setHasSubmittedNamedInsurance,
  namedEndorsements,
  setNamedEndorsements,
  modalTitlePrefix,
}) => {
  const handleAddInsuredName = (event, index) => {
    const enteredName = event.target.value;
    let updatedNamedEndorsements = [...namedEndorsements];
    updatedNamedEndorsements[index] = {
      namedEndorsement: enteredName,
    };
    setNamedEndorsements(updatedNamedEndorsements);
  };

  const handleDeleteNamedEndorsement = (index) => {
    const updatedNamedEndorsements = [...namedEndorsements];
    updatedNamedEndorsements.splice(index, 1);
    setNamedEndorsements(updatedNamedEndorsements);
  };

  return (
    <GridModal
      open={modalState[targetModalName]}
      title={`${modalTitlePrefix} Named Insuarance Endorsement`}
      onClose={() => {
        setModalState({
          ...modalState,
          [targetModalName]: false,
        });
      }}
      buttonText="Submit"
      buttonRadius="8px"
      handlePress={() => {
        setModalState({ ...modalState, [targetModalName]: false });
        setHasSubmittedNamedInsurance(true);
      }}
      capitalizeTitle={true}
    >
      <LayoutBox style={{ padding: '16px' }} gap={16}>
        {namedEndorsements.map((namedEndorsement, index) => (
          <LayoutBox row style={{ border: '2px solid #919eab52', borderRadius: '8px' }} padX="$1.5" padY="$1" justifyContent="space-between">
            <TextInput
              name="named-insurance"
              size="medium"
              color="#919EAB"
              bold
              placeholder="Name of Insured"
              padY="$0"
              value={namedEndorsement.namedEndorsement}
              onChange={(e) => handleAddInsuredName(e, index)}
            />
            {index !== 0 && (
              <Box cursor="pointer" onPress={() => handleDeleteNamedEndorsement(index)}>
                <TrashBinIconLarge />
              </Box>
            )}
          </LayoutBox>
        ))}
        <Button
          width="30%"
          label="Add Insured"
          borderRadius="8px"
          fontWeight="700"
          fontSize="14px"
          paddingBottom="6"
          paddingTop="6"
          paddingLeft="16"
          paddingRight="16"
          color="$gray-900"
          variant="text"
          border={{
            width: 2,
            color: '#919EAB52',
          }}
          onPress={() => {
            setNamedEndorsements([
              ...namedEndorsements,
              {
                namedEndorsement: '',
              },
            ]);
          }}
        />
      </LayoutBox>
    </GridModal>
  );
};

const UpdateInventoryEndorsementModal = ({
  modalState,
  setModalState,
  targetModalName,
  setHasSubmittedInventoryEndorsements,
  inventoryEndorsements,
  modalTitlePrefix,
  setGridReference,
}) => {
  const inventoryValueCol = [
    {
      field: 'locationName',
      headerName: 'Location Name',
      cellStyle: { textAlign: 'left' },
    },
    {
      field: 'value',
      headerName: 'Value',
      cellStyle: { textAlign: 'left' },
      editable: true,
      valueFormatter: (field) => currencyFormatter({ field }),
      cellDataType: 'number',
    },
  ];

  return (
    <GridModal
      open={modalState[targetModalName]}
      title={`${modalTitlePrefix} Inventory Value Endorsement`}
      buttonText="Submit"
      buttonRadius="8px"
      onClose={() => {
        setModalState({
          ...modalState,
          [targetModalName]: false,
        });
      }}
      handlePress={() => {
        setModalState({ ...modalState, [targetModalName]: false });
        setHasSubmittedInventoryEndorsements(true);
      }}
      capitalizeTitle={true}
    >
      <AlpineThemeGrid
        setGridReference={setGridReference}
        columnDefs={inventoryValueCol}
        rowData={inventoryEndorsements}
        headerBgColor="white"
        headerHeight={42}
      />
    </GridModal>
  );
};

const EndorsementsLayoutSection = ({
  type,
  endorsementData,
  setEndorsmentData,
  modalState,
  setModalState,
  setHasSubmittedInventoryEndorsements,
  setHasSubmittedNamedInsurance,
  setGridReference,
  setEndorsmentGridReference,
}) => {
  const { canExportEndorsements } = useAdjustmentState();
  const [isEndorsementListOpen, setIsEndorsementListOpen] = useState(false);

  const endorsementHeaders =
    type === 'named'
      ? [
          {
            field: 'namedEndorsement',
            headerName: 'Named Endorsement',
            resizable: false,
            cellStyle: { textAlign: 'left' },
          },
        ]
      : [
          {
            field: 'locationName',
            headerName: 'Location Name',
            cellStyle: { textAlign: 'left' },
          },
          {
            field: 'address',
            headerName: 'Address',
            cellStyle: { textAlign: 'left' },
          },
          {
            field: 'value',
            headerName: 'Value',
            cellStyle: { textAlign: 'left' },
            valueFormatter: (field) => currencyFormatter({ field }),

            cellDataType: 'number',
          },
        ];

  return (
    <LayoutBox width="100%">
      <LayoutBox
        row
        justifyContent="space-between"
        style={{ borderBottom: '1px solid #DFE3E8' }}
        width="100%"
        padY="$1.5"
        padX="$1"
        backgroundColor="#F8F8F8"
      >
        <LayoutBox row alignItems="center">
          {isEndorsementListOpen || canExportEndorsements ? (
            <Box cursor="pointer">
              <ExpandArrowIcon
                onPress={() => {
                  setIsEndorsementListOpen(false);
                }}
              />
            </Box>
          ) : (
            <Box cursor="pointer">
              <ForwardArrowIcon
                onPress={() => {
                  setIsEndorsementListOpen(true);
                }}
              />
            </Box>
          )}
          <Text value={type === 'named' ? 'Named Insurance Endorsement' : 'Inventory Value Endorsement'} bold />
          <Box
            marginLeft="8px"
            cursor="pointer"
            onPress={() => {
              const updatedModalState =
                type === 'named'
                  ? {
                      ...modalState,
                      editNamedEndorsement: true,
                    }
                  : {
                      ...modalState,
                      editInventoryEndorsements: true,
                    };

              setIsEndorsementListOpen(false);
              setModalState(updatedModalState);
            }}
          >
            <EditIcon />
          </Box>
        </LayoutBox>
        <LayoutBox>
          <TrashBinIconSmall />
        </LayoutBox>
      </LayoutBox>
      {(isEndorsementListOpen || canExportEndorsements) && (
        <LayoutBox width="100%">
          <AlpineThemeGrid
            setGridReference={type === 'named' ? setGridReference : setEndorsmentGridReference}
            columnDefs={endorsementHeaders}
            rowData={endorsementData}
            headerBgColor="white"
            headerHeight={type === 'named' ? 0 : 42}
          />
        </LayoutBox>
      )}
      {type === 'named' ? (
        <UpdateNamedEndorsementModal
          modalState={modalState}
          setModalState={setModalState}
          targetModalName="editNamedEndorsement"
          setHasSubmittedNamedInsurance={setHasSubmittedNamedInsurance}
          namedEndorsements={endorsementData}
          setNamedEndorsements={setEndorsmentData}
          modalTitlePrefix="Edit"
        />
      ) : (
        <UpdateInventoryEndorsementModal
          modalState={modalState}
          setModalState={setModalState}
          targetModalName="editInventoryEndorsements"
          setHasSubmittedInventoryEndorsements={setHasSubmittedInventoryEndorsements}
          inventoryEndorsements={endorsementData}
          setInventoryEndorsements={setEndorsmentData}
          modalTitlePrefix="Edit"
          setGridReference={setGridReference}
        />
      )}
    </LayoutBox>
  );
};

export const Endorsements = React.forwardRef((props, ref) => {
  const { quotes, setGridReference, setEndorsmentGridReference } = props;
  const [modalState, setModalState] = useState({
    addEndorsement: false,
    addNamedInsurance: false,
    addInventoryValue: false,
    editNamedEndorsement: false,
    editInventoryEndorsements: false,
  });
  const [selectedEndorsment, setSelectedEndorsement] = useState('');
  const [namedEndorsements, setNamedEndorsements] = useState([
    {
      namedEndorsement: '',
    },
  ]);
  const [hasSubmittedNamedInsurance, setHasSubmittedNamedInsurance] = useState(false);
  const [inventoryEndorsements, setInventoryEndorsements] = useState();
  const [hasSubmittedInventoryEndorsements, setHasSubmittedInventoryEndorsements] = useState(false);

  useEffect(() => {
    const quoteInnventories = quotes.locations.map((location) => ({
      locationName: location.name,
      value: 0,
      address: location.address,
    }));
    setInventoryEndorsements(quoteInnventories);
  }, [quotes]);

  const endorsementOptions = [
    {
      value: 'namedInsurance',
      label: 'Named Insurance',
    },
    {
      value: 'inventoryValue',
      label: 'Inventory Value',
    },
  ];

  const handleAddEndorsement = () => {
    if (selectedEndorsment === 'namedInsurance') {
      setModalState({
        ...modalState,
        addEndorsement: false,
        addNamedInsurance: true,
      });
    } else if (selectedEndorsment === 'inventoryValue') {
      setModalState({
        ...modalState,
        addEndorsement: false,
        addInventoryValue: true,
      });
    }
  };

  return (
    <LayoutBox flexDirection={'column'} layout="bottom-left" justifyContent="space-between" width="100%" backgroundColor="white">
      {!hasSubmittedNamedInsurance && !hasSubmittedInventoryEndorsements && (
        <LayoutBox row style={{ padding: '16px' }} alignSelf="stretch" alignItems="center" gap={16} justifyContent="center" padX="$3">
          <LayoutBox
            row
            alignSelf="stretch"
            gap={32}
            alignItems="center"
            style={{ padding: '40px', borderRadius: '8px', border: '1px dashed rgba(145, 158, 171, 0.32)', backgroundColor: '#F4F6F8' }}
          >
            <Box style={{ padding: '44px' }}>
              <NoEndorsmentIcon />
            </Box>
            <LayoutBox style={{ display: 'flex', flexDirection: 'column' }} alignItems="flex-start">
              <Text style={{ fontWeight: '700', fontSize: '20px' }}>No Endorsements</Text>
              <Text style={{ fontWeight: '700', fontSize: '14px' }} color="#637381">
                Add policy wide endorsements here.
              </Text>
            </LayoutBox>
          </LayoutBox>
        </LayoutBox>
      )}
      {hasSubmittedNamedInsurance && (
        <EndorsementsLayoutSection
          type="named"
          endorsementData={namedEndorsements}
          setEndorsmentData={setNamedEndorsements}
          modalState={modalState}
          setModalState={setModalState}
          setHasSubmittedInventoryEndorsements={setHasSubmittedInventoryEndorsements}
          setHasSubmittedNamedInsurance={setHasSubmittedNamedInsurance}
          setGridReference={setGridReference}
          setEndorsmentGridReference={setEndorsmentGridReference}
        />
      )}
      {hasSubmittedInventoryEndorsements && (
        <EndorsementsLayoutSection
          type="inventory"
          endorsementData={inventoryEndorsements}
          setEndorsmentData={setInventoryEndorsements}
          modalState={modalState}
          setModalState={setModalState}
          setHasSubmittedInventoryEndorsements={setHasSubmittedInventoryEndorsements}
          setHasSubmittedNamedInsurance={setHasSubmittedNamedInsurance}
          setGridReference={setGridReference}
          setEndorsmentGridReference={setEndorsmentGridReference}
        />
      )}
      <LayoutBox padX="$2" padY="$3">
        <Button
          borderRadius="8px"
          fontWeight="700"
          paddingBottom="6"
          paddingTop="6"
          paddingLeft="16"
          paddingRight="16"
          color="$gray-900"
          variant="text"
          border={{
            width: 2,
            color: '#919EAB52',
          }}
          label="Add Endorsements"
          onPress={() => {
            setModalState({
              ...modalState,
              addEndorsement: true,
            });
          }}
        />
      </LayoutBox>

      <GridModal
        open={modalState.addEndorsement}
        title="Add Endorsements"
        onClose={() => {
          setModalState({
            ...modalState,
            addEndorsement: false,
          });
        }}
        buttonText="Add"
        buttonRadius="8px"
        handlePress={handleAddEndorsement}
        capitalizeTitle={true}
      >
        <Box padX="$3" padY="$3">
          <RadioGroup
            name="roofPitchIsOverLimit"
            value={selectedEndorsment.value}
            onChange={(v) => setSelectedEndorsement(v)}
            alignSelf="flex-start"
            alignItems="flex-start"
            padLeft="$0.5"
          >
            {endorsementOptions.map((opt, i) => (
              <Radio key={`endorsement_${i}`} value={opt.value} label={opt.label} labelProps={{ bold: true }} color="#637381" onColor="#637381" />
            ))}
          </RadioGroup>
        </Box>
      </GridModal>

      <UpdateNamedEndorsementModal
        modalState={modalState}
        setModalState={setModalState}
        targetModalName="addNamedInsurance"
        setHasSubmittedNamedInsurance={setHasSubmittedNamedInsurance}
        namedEndorsements={namedEndorsements}
        setNamedEndorsements={setNamedEndorsements}
        modalTitlePrefix="Add"
      />

      <UpdateInventoryEndorsementModal
        modalState={modalState}
        setModalState={setModalState}
        targetModalName="addInventoryValue"
        setHasSubmittedInventoryEndorsements={setHasSubmittedInventoryEndorsements}
        inventoryEndorsements={inventoryEndorsements}
        setInventoryEndorsements={setInventoryEndorsements}
        modalTitlePrefix="Add"
        setGridReference={setGridReference}
      />
    </LayoutBox>
  );
});
