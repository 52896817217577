import React, { useEffect, useRef, useState } from 'react';
import { Modal, Text, Box } from '../ui';
import { EllipsisIcon } from './icons';

const DropdownMenu = ({ options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const ellipsisRef = useRef(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [hoverIndex, setHoverIndex] = useState('');

  useEffect(() => {
    if (ellipsisRef.current) {
      const { top, left, height } = ellipsisRef.current.getBoundingClientRect();
      setPosition({ top: top + height, left });
    }
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box className="dropdown-menu">
      <Box
        ref={ellipsisRef}
        style={{
          display: 'flex',
          backgroundColor: 'transparent',
          justifyContent: 'flex-end',
          marginLeft: '-10px',
        }}
        onClick={toggleMenu}
      >
        <EllipsisIcon />
      </Box>
      {isOpen && (
        <Modal open={isOpen} BackdropProps={{ invisible: true }} onClose={() => setIsOpen(false)}>
          <Box
            ref={dropdownRef}
            style={{
              position: 'absolute',
              top: position.top,
              left: position.left - 160,
              zIndex: 9999,
            }}
            width="200px"
          >
            <div
              style={{
                height: '8px',
                display: 'flex',
                backgroundColor: 'transparent',
                justifyContent: 'flex-end',
                width: '100%',
                marginLeft: '-16px',
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 8" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18 8L10.591 0.653454C9.71231 -0.217818 8.28769 -0.217818 7.40901 0.653454L0 8H18Z"
                  fill="white"
                />
              </svg>
            </div>
            <Box
              style={{
                display: 'inline-flex',
                flexDirection: 'column',
                borderRadius: '12px',
                border: '1px solid white',
                overflow: 'hidden',
                background: 'var(--Background-Light-Paper, #FFF)',
                boxShadow: ' 0px 0px 2px 0px rgba(145, 158, 171, 0.24), -20px 20px 40px -4px rgba(145, 158, 171, 0.24)',
              }}
            >
              {options.map((option, index) => (
                <Box
                  style={{
                    display: 'flex',
                    padding: '8px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '4px',
                    alignSelf: 'stretch',
                    fontWeight: 600,
                    width: '200px',
                    color: '#212B36',
                    transition: 'background-color 0.3s',
                    backgroundColor: hoverIndex === index ? 'rgba(33, 150, 243, 0.1)' : 'white', // Optional: add transition for smooth effect
                  }}
                  key={option.label}
                  onMouseEnter={() => setHoverIndex(index)}
                  onPress={() => {
                    option.onClick();
                    setIsOpen(false);
                  }}
                >
                  <Text
                    value={option.label}
                    style={{
                      backgroundColor: 'transparent',
                      fontWeight: 500,
                      width: '200px',
                      color: '#212B36',
                      cursor: 'pointer',
                    }}
                  ></Text>
                </Box>
              ))}
            </Box>
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default DropdownMenu;
