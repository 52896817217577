import { LayoutBox } from '../../components';
import { Box, GridModal, Text } from '../../ui';

const SaveDraftMCEModal = ({ showModal, setShowModal, onClose, handlePress, handleDiscardChanges }) => {
  return (
    <GridModal
      title="Are You Sure You Want to Exit?"
      buttonText="Save and Exit"
      handlePress={handlePress}
      open={showModal}
      onClose={onClose}
      width="480px"
      capitalizeTitle={true}
      onCancel={handleDiscardChanges}
      cancelButtonText="Exit Without Saving"
    >
      <LayoutBox gap={16} padding="16px">
        <Box style={{ color: '#212B36', fontWeight: '500', fontSize: '14px', textAlign: 'start' }} alignItems="flex-start">
          <Text value="There are unsaved changes on your MCE. " bold></Text>
        </Box>
      </LayoutBox>
    </GridModal>
  );
};

export default SaveDraftMCEModal;
