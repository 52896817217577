import React from 'react';
import { Transition, withStyles } from '../../styling';
import { Checkbox } from '../Checkbox';
import { Box } from '../Box';
import { useRadioGroup } from '../RadioGroup';
import { isNull } from '../../utils';

const RadioIcon = ({ checked = false, color, onColor, ...rest }) => (
  <Box
    size={22}
    margin={1}
    borderRadius="$circle"
    borderWidth={2}
    justifyContent="center"
    alignItems="center"
    borderColor={checked ? color : onColor}
    backgroundColor="transparent"
    {...rest}
  >
    <Transition
      items="checkbox"
      animations={{
        initial: {
          opacity: checked ? 1 : 0,
          scale: checked ? 1 : 0,
        },
        update: {
          opacity: checked ? 1 : 0,
          scale: checked ? 1 : 0,
        },
        config: {
          clamp: true,
          tension: 500,
          friction: 35,
        },
      }}
    >
      <Box size={12} borderRadius="$circle" backgroundColor={color} />
    </Transition>
  </Box>
);

const DefaultIcon = <RadioIcon />;
const DefaultCheckedIcon = <RadioIcon checked />;

const Radio = withStyles(
  { root: {} },
  { name: 'Radio', forward: true }
)(
  React.forwardRef(function Radio(props, ref) {
    const { checked: checkedProp, name: nameProp, onChange, value, accessibility, color, onColor, ...rest } = props;

    const radioGroup = useRadioGroup();
    let name = nameProp;
    let checked = checkedProp;

    if (radioGroup) {
      if (isNull(name)) {
        name = radioGroup.name;
      }
      if (checked === undefined && name === radioGroup.name) {
        checked = radioGroup.value === props.value;
      }
    }

    const handleChange = (checkedState, ...args) => {
      if (onChange) {
        onChange(checkedState, ...args);
      }
      if (radioGroup && radioGroup.onChange) {
        radioGroup.onChange(value, ...args);
      }
    };

    return (
      <Checkbox
        ref={ref}
        icon={DefaultIcon}
        checkedIcon={DefaultCheckedIcon}
        onChange={handleChange}
        checked={checked}
        accessibility={{
          accessibilityRole: 'radio',
          ...accessibility,
        }}
        {...rest}
        color={color}
        onColor={onColor}
      />
    );
  })
);

Radio.icon = DefaultIcon;
Radio.checkedIcon = DefaultCheckedIcon;

export { Radio };
