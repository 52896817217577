import React, { useState } from 'react';
import { withStyles } from '../../../../ui/styling';
import { StarFilledIcon, StarIcon } from '../../../../components/icons';
import { Box, GridModal, Heading, Text } from '../../../../ui';
import { Chip } from '../../../../components';
import { Colors } from '../../../../constants';
import moment from 'moment-timezone';

export const QuoteNav = withStyles(({ theme }) => ({
  root: {},
  orderDetails: {
    marginBottom: '$40',
  },
  orderDetailsCard: {
    marginBottom: '$24',
    padding: '$32',
  },
  orderDiscRow: {
    display: 'flex',
    gap: '$8',
    alignItems: 'flex-start',
    marginBottom: '$16',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    marginBottom: 8,
  },
  orderPointersList: {
    color: '#919EAB',
    fontSize: 14,
    lineHeight: 22,
    fontWeight: 700,
    display: 'flex',
    rowGap: 6,
    columnGap: 16,
    flexWrap: 'wrap',
  },
}))(
  React.forwardRef(function QuoteNav(props, ref) {
    const { styles, data, expiration, historyDisplayText } = props;
    const { insured, product, quoteNumber } = data;
    const [openUpdateHeroQuoteModal, setOpenUpdateHeroQuoteModal] = useState(false);
    const [confirmUpdateHeroQuote, setConfirmUpdateHeroQuote] = useState(false);

    return (
      <Box>
        <Box style={styles.titleContainer} flexDirection="row">
          <Box cursor="pointer" onPress={() => setOpenUpdateHeroQuoteModal(true)}>
            {confirmUpdateHeroQuote ? <StarFilledIcon /> : <StarIcon />}
          </Box>
          <Heading level={3}>{insured}</Heading>
          <Chip color={Colors.quoteStatus[data.status] || '$gray.100'}>{data.status}</Chip>
        </Box>
        <Box style={styles.orderPointersList} flexDirection="row" justifyContent="space-between">
          <span style={{ color: '#212B36 ' }}>Effective {moment.utc(data.coverageAt).format('MMM D, YYYY')}</span>

          <li>{expiration}</li>

          <li>Quote No. {quoteNumber}</li>

          <li>{historyDisplayText}</li>

          <li>{product.name}</li>
        </Box>
        <GridModal
          open={openUpdateHeroQuoteModal}
          title="Confirm Hero Quote Change"
          buttonText="Confirm"
          buttonRadius="8px"
          onClose={() => setOpenUpdateHeroQuoteModal(false)}
          handlePress={() => {
            setConfirmUpdateHeroQuote(true);
            setOpenUpdateHeroQuoteModal(false);
          }}
          capitalizeTitle={true}
        >
          <Box padX="16px" padY="16px">
            <Text value="Are you sure you wish to set this quote as the Hero Quote?" bold textAlign="left" small />
            <Text value="This will remove the Hero status from the current Hero Quote." bold textAlign="left" small />
          </Box>
        </GridModal>
      </Box>
    );
  })
);
