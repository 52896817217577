import { LayoutBox } from '../../components';
import { Box, GridModal, Text } from '../../ui';
const ExecuteMCEModal = ({ showModal, setShowModal, loading, disabled, handlePress }) => {
  return (
    <GridModal
      title="Execute MCE"
      buttonText="Execute"
      handlePress={handlePress}
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      width="480px"
      capitalizeTitle={true}
      disabled={disabled}
      loading={loading}
    >
      <LayoutBox gap={16} padding="16px">
        <Box style={{ color: '#212B36', fontWeight: '500', fontSize: '14px', textAlign: 'start' }} alignItems="flex-start">
          <Text value="Please confirm that you've completed your MCE and you wish to amend these changes to the existing policy." bold></Text>
        </Box>
        <Box style={{ color: '#637381', fontWeight: '500', fontSize: '14px', textAlign: 'start' }} alignItems="flex-start">
          <Text value="Executed MCEs are not Editable, but can be Deleted or Duplicated." bold></Text>
        </Box>
      </LayoutBox>
    </GridModal>
  );
};

export default ExecuteMCEModal;
