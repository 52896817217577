import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Grid } from '../../../../ui/components/Grid';
import { currencyFormatter } from './utils';
import { Box, Button } from '../../../../ui';
import { useAdjustmentState } from '../../context/QuoteAdjustment/AdjustmentContext';
import { useAdjustmentForm } from '../../context/QuoteAdjustment/AdjustmentFormContext';
import { ImportModal } from '../../../../components/ImportModal';
import { LayoutBox } from '../../../../components';
import { validateCSVData } from '../../../../utils';
import { isEmpty } from '../../../../ui/utils';
import ResetQuoteModal from '../../../../components/ResetQuoteModal';

export const Fees = React.forwardRef((props, ref) => {
  const { setGridReference, onSelectionChanged, quotes, setQuoteData, gridReference, importModal, setImportModal, setExportHandler } = props;
  const { enable, adjusting } = useAdjustmentState();
  const { setValue, getValues } = useAdjustmentForm();
  const locations = quotes?.locations;
  const fees = quotes.fees;
  const isEditable = () => quotes.status === 'pending' && adjusting;
  const [selectedFileRows, setSelectedFileRows] = useState([]);
  const [errors, setErrors] = useState([]);

  const formatCurrency = (value) => currencyFormatter({ field: { value }, withDecimals: true });

  const formatPercentage = (value) => {
    if (value === 0 || isNaN(value)) {
      return 'N/A';
    }
    const formattedValue = Number(value).toFixed(2);
    // Check if the formatted value is '0.00' but the actual value is non-zero
    if (formattedValue === '0.00' && value > 0) {
      return '< 0.01%';
    }
    return `${formattedValue}%`;
  };

  const calculateAmount = (rateOfNet, totalGrossPremium) => {
    return rateOfNet ? (Number(rateOfNet) / 100) * Number(totalGrossPremium) : 0;
  };

  const onCellValueChanged = (params) => {
    params.api.flashCells({
      rowNodes: [params.node],
      columns: [params.column],
    });
  };

  const getCollateralFee = (totalGrossPremium) => {
    const collateral = fees.find((fee) => /collateral/.test(fee.name.toString().toLowerCase()));

    const percentageOfGWP = totalGrossPremium > 0 ? Number((collateral?.amount / totalGrossPremium) * 100) : 0;
    if (collateral)
      return {
        id: collateral.id,
        title: 'All-in Cost',
        fee: collateral.name,
        annual: collateral.amount || 0,
        percentageOfGWP: collateral.rateOfNet || percentageOfGWP,
        headerType: '',
        format: true,
      };
  };

  const getBrokerageFee = () => {
    const brokerage = fees?.find((fee) => /broker/.test(fee.name.toString().toLowerCase()));
    if (brokerage)
      return {
        id: brokerage.id,
        title: 'All-in Cost',
        fee: brokerage.name,
        annual: brokerage.amount || 0,
        percentageOfGWP: brokerage.rateOfNet || 0,
        headerType: '',
        format: true,
        editable: true,
        otherFee: true,
      };
  };

  const setGridValues = useCallback(
    (params, totalGrossPremium) => {
      enable();
      const { data, newValue } = params;
      const { id } = data;
      const allFees = getValues('quote.fees');
      const editedFieldMap = {
        fee: 'name',
        annual: 'amount',
        percentageOfGWP: 'rateOfNet',
      };
      const editedField = editedFieldMap[params.colDef.field] || '';
      const rowIndex = allFees.findIndex((fee) => fee.id === id);
      const value = params.colDef.dataType === 'number' ? parseFloat(newValue) : newValue;

      allFees[rowIndex][editedField] = isNaN(value) && editedField !== 'name' ? 0 : value;
      const updatedFee = { ...allFees[rowIndex] };
      if (editedField === 'amount') {
        updatedFee.rateOfNet = null;
        setValue(`quote.fees.${rowIndex}.rateOfNet`, null);
      } else if (editedField === 'rateOfNet') {
        const newAmount = !isNaN(value) ? Number(calculateAmount(value, totalGrossPremium).toFixed(2)) : updatedFee.amount;
        if (value && !isNaN(value)) {
          updatedFee.amount = newAmount;
          setValue(`quote.fees.${rowIndex}.amount`, newAmount);
        }
      } else if (updatedFee[editedField]) updatedFee[editedField] = value;
      const defaultValue = editedField === 'rateOfNet' ? null : 0;
      setValue(`quote.fees.${rowIndex}.${editedField}`, editedField !== 'name' && (isNaN(value) || !value) ? defaultValue : value);
      setQuoteData((prev) => {
        const updatedFees = [...prev.fees];
        updatedFees[rowIndex] = updatedFee;
        return { ...prev, fees: updatedFees };
      });
    },
    [getValues, setValue, setQuoteData, enable, adjusting]
  );

  const calculateReinsurancePremium = (locations = []) => {
    return locations.reduce((total, location) => {
      const autoFacPremium = location.reinsurance?.autoFacPremium ?? 0;
      const additionalFacPremium = location.reinsurance?.additionalFacPremium ?? 0;

      const totalFacExpenses = location.reinsurance?.facProgramExpenses ?? 0;
      return total + autoFacPremium + additionalFacPremium + totalFacExpenses;
    }, 0);
  };

  const calculateTotalGrossWrittenPremium = (locations = []) => {
    return locations.reduce((total, location) => {
      return total + location.coverages.reduce((subTotal, coverage) => subTotal + coverage.netPremium, 0);
    }, 0);
  };

  const generateUniqueNames = (fees = []) => {
    const nameCount = {};
    return fees
      .filter((fee) => !/broker|collateral/.test((fee?.name).toString().toLowerCase()))
      .map((fee) => {
        const baseName = fee.name;
        nameCount[baseName] = (nameCount[baseName] || 0) + 1;
        const uniqueName = nameCount[baseName] > 1 ? `${baseName} ${nameCount[baseName] - 1}` : baseName;

        return {
          fee: uniqueName,
          annual: fee.amount || 0,
          percentageOfGWP: fee.rateOfNet || 0,
          id: fee.id,
          otherFee: true,
          editable: true,
          format: true,
        };
      });
  };

  const sumFeesField = (fees, field) => {
    return fees.filter((fee) => !/broker|collateral/.test((fee?.name).toString().toLowerCase())).reduce((sum, fee) => sum + +fee[field] || 0, 0);
  };

  const feesSchema = useMemo(
    () => [
      { field: 'title', rowGroup: true, hide: true },
      {
        field: 'headerType',
        rowGroup: true,
        hide: true,
        cellRenderer: (params) => params.value || '',
        dataType: 'string',
      },
      {
        field: 'annual',
        minWidth: 300,
        editable: (params) => params.data?.editable && isEditable(),
        valueSetter: (params) => {
          params.api.flashCells({ rowNodes: [params.node], columns: ['annual', 'percentageOfGWP'] });
          setGridValues(params, totalGrossPremium);
        },
        dataType: 'number',
        valueFormatter: (params) => params.data && params.data.format && formatCurrency(params.data.annual),
        cellClassRules: {
          'yellow-background': (params) => params.node.key === 'Total Gross Written Premium',
        },
      },
      {
        field: 'percentageOfGWP',
        minWidth: 300,
        editable: (params) => params.data?.editable && isEditable(),
        dataType: 'number',
        valueSetter: (params) => setGridValues(params, totalGrossPremium),
        valueFormatter: (params) => params.data && params.data.format && formatPercentage(params.data.percentageOfGWP),
      },
    ],
    []
  );

  const autoGroupColumnDef = useMemo(
    () => ({
      field: 'fee',
      headerName: 'Fee',
      editable: (params) => params.data?.editable && isEditable(),
      valueSetter: (params) => setGridValues(params),
      minWidth: 300,
      cellRendererParams: { suppressCount: true },
      headerCheckboxSelection: isEditable(),
      checkboxSelection: (params) => params.data?.otherFee && isEditable(),
    }),
    []
  );

  const brokerageFee = getBrokerageFee() || {};
  const brokerageCommission = brokerageFee?.annual || 0;
  const totalGrossPremium = calculateTotalGrossWrittenPremium(locations);
  const reinsurancePremium = calculateReinsurancePremium(locations);
  const primaryPremium = totalGrossPremium - reinsurancePremium;
  const updatedFees = generateUniqueNames(fees);
  const totalFeesAnnual = sumFeesField(fees, 'amount');
  const totalRateOfNet = sumFeesField(fees, 'rateOfNet');
  const totalAutoFacPremium = quotes.locations.reduce((sum, location) => sum + +location.reinsurance?.autoFacPremium || 0, 0);
  const totalAdditionalFacPremium = quotes.locations.reduce((sum, location) => sum + +location.reinsurance?.additionalFacPremium || 0, 0);
  const autoFacPremiumGWP = totalGrossPremium > 0 ? Number((totalAutoFacPremium / totalGrossPremium) * 100) : 0;
  const additionalFacPremiumGWP = totalGrossPremium > 0 ? Number((totalAdditionalFacPremium / totalGrossPremium) * 100) : 0;
  const collateralFee = getCollateralFee(totalGrossPremium) || {};
  const collateral = collateralFee?.annual || 0;
  const primaryPremiumGWP = totalGrossPremium > 0 ? Number((primaryPremium / totalGrossPremium) * 100) : 0;
  const reinsuranceGWP = totalGrossPremium > 0 ? Number((reinsurancePremium / totalGrossPremium) * 100) : 0;
  const collateralGWP = collateralFee?.percentageOfGWP
    ? collateralFee?.percentageOfGWP
    : totalGrossPremium > 0
    ? Number((collateral / totalGrossPremium) * 100)
    : 0;
  const brokerGWP = brokerageFee?.annual
    ? brokerageFee?.percentageOfGWP
    : totalGrossPremium > 0
    ? Number((brokerageCommission / totalGrossPremium) * 100)
    : 0;

  const totalFacExpenses = quotes.locations.reduce((sum, location) => sum + +location.reinsurance?.facProgramExpenses || 0, 0);
  const facExpensesGWP = totalGrossPremium > 0 ? Number((totalFacExpenses / totalGrossPremium) * 100) : 0;

  // Row data
  const rowDataItems = [
    {
      id: uuid(),
      title: 'All-in Cost',
      fee: 'Primary Premium',
      annual: primaryPremium,
      percentageOfGWP: primaryPremiumGWP,
      headerType: 'Gross Written Premium',
      format: true,
    },
    {
      id: uuid(),
      title: 'All-in Cost',
      fee: 'Reinsurance Premium',
      annual: reinsurancePremium,
      percentageOfGWP: autoFacPremiumGWP + additionalFacPremiumGWP + facExpensesGWP,
      format: true,
      headerType: 'Gross Written Premium',
    },
    {
      id: uuid(),
      title: 'All-in Cost',
      fee: 'Auto Fac Premium',
      annual: totalAutoFacPremium,
      percentageOfGWP: autoFacPremiumGWP,
      headerType: 'Gross Written Premium',
      subHeaderType: 'Reinsurance Premium',
      format: true,
    },
    {
      id: uuid(),
      title: 'All-in Cost',
      fee: 'Additional Fac Premium',
      annual: totalAdditionalFacPremium,
      percentageOfGWP: additionalFacPremiumGWP,
      headerType: 'Gross Written Premium',
      subHeaderType: 'Reinsurance Premium',
      format: true,
    },
    {
      id: uuid(),
      title: 'All-in Cost',
      fee: 'Fac Program Expenses',
      annual: totalFacExpenses,
      percentageOfGWP: facExpensesGWP,
      headerType: 'Gross Written Premium',
      subHeaderType: 'Reinsurance Premium',
      format: true,
    },
    {
      id: uuid(),
      title: 'All-in Cost',
      fee: 'Total Gross Written Premium',
      annual: totalGrossPremium,
      percentageOfGWP: primaryPremiumGWP + reinsuranceGWP,
      headerType: '',
      format: true,
    },
    ...(!isEmpty(collateralFee) ? [collateralFee] : []),
    ...(!isEmpty(brokerageFee) ? [brokerageFee] : []),
    {
      id: uuid(),
      fee: 'Total All-in Cost',
      annual: collateral + brokerageCommission + totalGrossPremium,
      percentageOfGWP: +collateralGWP + primaryPremiumGWP + reinsuranceGWP + +brokerGWP,
      title: '',
      format: true,
    },
    ...updatedFees,
    {
      id: uuid(),
      fee: 'Total',
      annual: totalFeesAnnual + collateral + brokerageCommission + totalGrossPremium,
      percentageOfGWP: totalRateOfNet + primaryPremiumGWP + reinsuranceGWP + collateralGWP + brokerGWP,
      title: '',
      format: true,
    },
  ];

  const handlePress = () => {
    const newRow = {
      id: uuid(),
      name: 'New Fee',
      amount: 0,
      rateOfNet: 0,
    };
    setQuoteData((pre) => {
      return { ...pre, fees: [...pre.fees, newRow] };
    });
    setValue(`quote.fees`, [...quotes.fees, newRow]);
    gridReference.api.applyTransaction({ add: [newRow] });
  };

  const validateData = (data) => {
    const newErrors = validateCSVData(feesSchema, data);
    setErrors(newErrors);
  };

  const addImportedFileRows = () => {
    let updatedQuoteData = { ...quotes };

    const formattedFeesData = selectedFileRows.map((item) => {
      const transformedItem = { id: uuid() };
      feesSchema.forEach((schema) => {
        const { field, headerName } = schema;
        const data =
          typeof value === 'string' && (item[headerName]?.includes('%') || item[headerName]?.includes('$'))
            ? parseFloat(item[headerName].replace(/\$|,/g, '').replace(/%/g, ''))
            : item[headerName];
        transformedItem[field] = data;
      });
      return transformedItem;
    });

    updatedQuoteData.fees = [...updatedQuoteData.fees, ...formattedFeesData];
    setValue('quote.fees', updatedQuoteData.fees);
    setQuoteData(updatedQuoteData);
  };

  const gridOptions = {
    getRowStyle: (params) => {
      if (params.data?.otherFee) {
        return { background: '#e0f7fa' };
      }
      return null;
    },
    getRowId: (params) => params.data.id,
    enableCellChangeFlash: true,
  };

  const exportToCsv = () => {
    const rowGroups = [];
    rowGroups.push(['Fee', 'Annual', 'Percentage of GWP']);

    // Iterate over all nodes (including group rows)
    gridReference.api.forEachNode((node) => {
      const rowData = [];
      let indentation = '';

      if (node.level === 1) {
        indentation = '   ';
      } else if (node.level === 2) {
        indentation = '      ';
      } else if (node.level === 3) {
        indentation = '         ';
      }

      if (node.group) {
        rowData.push(`${indentation}${node.key}`);
        rowData.push('');
        rowData.push('');
      } else {
        rowData.push(`${indentation}${node.data.fee || ''}`);

        const annualValue = node.data.annual ? parseFloat(node.data.annual).toFixed(2) : '';
        rowData.push(annualValue);

        const percentageValue = node.data.percentageOfGWP ? (parseFloat(node.data.percentageOfGWP) / 100).toFixed(2) : '';
        rowData.push(percentageValue);
      }

      rowGroups.push(rowData);
    });

    // Convert the row data to CSV format
    const csvContent = rowGroups.map((row) => row.map((cell) => `"${cell}"`).join(',')).join('\n');
    return csvContent;
  };

  useEffect(() => {
    setExportHandler(exportToCsv);
  }, [gridReference, feesSchema]);

  return (
    <div style={{ marginBottom: '10px', width: '100%' }}>
      <div className="ag-theme-quartz">
        <Grid
          data={rowDataItems}
          columns={feesSchema}
          floatingFilter={false}
          autoGroupColumnDef={autoGroupColumnDef}
          groupDefaultExpanded={1}
          setGridReference={setGridReference}
          onSelectionChanged={onSelectionChanged}
          rowSelection="multiple"
          getDataPath={(data) => {
            if (!data?.title) return [data.fee];
            if (!data?.headerType) return [data.title, data.fee];
            if (data.headerType !== '' && !data?.subHeaderType) return [data.title, data.headerType, data.fee];
            return [data.title, data.headerType, data.subHeaderType, data.fee];
          }}
          treeData
          shouldShowPagination={false}
          gridOptions={gridOptions}
          onCellValueChanged={onCellValueChanged}
        />
      </div>
      {adjusting && (
        <LayoutBox row justifyContent="space-between">
          <Box display="flex" alignItems="start" justifyContent="center" flexWrap="wrap" backgroundColor="white" height={70}>
            <Box border="1px solid #919EAB52" marginLeft="$2" borderRadius={8}>
              <Button color="#000" variant="text" onPress={handlePress}>
                Add Fees
              </Button>
            </Box>
          </Box>
          <ResetQuoteModal quoteId={quotes.id} />
        </LayoutBox>
      )}
      <LayoutBox Row>
        <ImportModal
          importModal={importModal}
          setImportModal={setImportModal}
          setSelectedFileRows={(data) => {
            setSelectedFileRows(data);
            validateData(data);
          }}
          selectedFileRows={selectedFileRows}
          errors={errors}
          setErrors={setErrors}
          addImportedFileRows={addImportedFileRows}
        />
      </LayoutBox>
    </div>
  );
});
