import React from 'react';
import loaderIcon from '../../components/icons/loader.png';
import { Modal } from '../../ui';
const LoaderModal = ({ loading, setLoading }) => {
  return (
    <Modal open={loading} onClose={() => setLoading(false)}>
      <div className="loader-modal">
        <img src={loaderIcon} alt="loader-icon" width="250px" height={'250px'} className="spinner-container" />
      </div>
    </Modal>
  );
};

export default LoaderModal;
