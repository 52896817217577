import { LayoutBox } from '../components';

const PDFViewer = () => {
  return (
    <LayoutBox
      style={{
        display: 'flex',
        height: '946px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '10px',
        flexShrink: 0,
        alignSelf: 'stretch',
      }}
      paddingY="24px"
      paddingX="40px"
      width="100%"
    >
      <iframe
        title="pdf-doc"
        src="/pdfjs-4.2.67-dist/web/viewer.html?file=mcepage.pdf#page=2&zoom=100&pagemode=thumbs"
        height="100%"
        width="100%"
      ></iframe>
    </LayoutBox>
  );
};

export default PDFViewer;
