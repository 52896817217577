import { useState } from 'react';
import { AlpineThemeGrid } from '../../../../ui/components/Grid';
import { currencyFormatter } from './utils';
import { useAdjustmentState } from '../../context/QuoteAdjustment/AdjustmentContext';
import { useQuoteData } from '../../context/QuoteDataContext';
import { Box, GridModal, Text } from '../../../../ui';

export const MiscValues = (props) => {
  const { setGridReference } = props;
  const { adjusting, confirmSaveAdjustment, setConfirmSaveAdjustment } = useAdjustmentState();
  const { maxCoverageLimits, setMaxCoverageLimit } = useQuoteData();
  const [limitValues, setLimitValues] = useState(maxCoverageLimits);

  const colDef = [
    {
      field: 'valueName',
      headerName: 'Value Name',
      cellStyle: { textAlign: 'left' },
    },
    {
      field: 'maxLimit',
      headerName: 'Maximum Limit',
      cellStyle: { textAlign: 'left' },
      editable: adjusting,
      valueFormatter: (field) => currencyFormatter({ field }),
      cellDataType: 'number',
    },
  ];

  const handleValueChange = (cell) => {
    const { valueName, maxLimit } = cell?.data;

    const updateMiscValues = maxCoverageLimits.map((value) => {
      if (value.valueName === valueName) value.maxLimit = maxLimit;
      return value;
    });

    setLimitValues(updateMiscValues);
  };

  return (
    <>
      <AlpineThemeGrid
        setGridReference={setGridReference}
        columnDefs={colDef}
        rowData={maxCoverageLimits}
        headerBgColor="light-gray"
        onCellEditingStopped={handleValueChange}
      />
      <GridModal
        open={confirmSaveAdjustment}
        title="Are you sure you want to save changes?"
        onClose={() => setConfirmSaveAdjustment(false)}
        buttonText="Save Changes"
        buttonRadius="8px"
        handlePress={() => {
          setMaxCoverageLimit(limitValues);
          setConfirmSaveAdjustment(false);
        }}
        capitalizeTitle={false}
      >
        <Box padX="16px" padY="16px">
          <Text
            value="Corresponding Values in Coverages will be defaulted to Maximum Limit if they exceed the Maximum Limit threshold."
            bold
            textAlign="left"
            small
          />
        </Box>
      </GridModal>
    </>
  );
};
