import React from 'react';
import { withStyles } from '../ui/styling';
import { Box, Text } from '../ui';
import { normalizeCamelCase } from '../ui/utils';

export const Chip = withStyles(({ theme, color, textColor }) => ({
  root: {
    borderRadius: 6,
    backgroundColor: color ? color : '$gray.400',
    padX: '$1.75',
    padY: '$0.75',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    props: {
      size: 'xSmall',
      weight: 600,
      onColor: color ? color : '$gray.400',
      align: 'center',
      color: textColor || '$gray.600',
      uppercase: true,
    },
  },
}))((props) => {
  const { styles, color, children, ...rest } = props;
  return (
    <Box {...rest}>
      <Text style={styles.text} {...styles.props.text}>
        {normalizeCamelCase(children)}
      </Text>
    </Box>
  );
});
